import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxToastr from 'react-redux-toastr';
import thunk from 'redux-thunk';
import "core-js/stable";
import "regenerator-runtime/runtime";

import App from 'my-views/App';
import appReducers from 'my-reducers/index';
import './i18n';

const store = createStore(
    appReducers, /* preloadedState, */
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunk),
);

ReactDOM.render(
    <Provider store={store}>
        <div>
            <App/>
            <ReduxToastr preventDuplicates />
        </div>
    </Provider>, document.getElementById('root')
);
