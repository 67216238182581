import React, { useContext } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import { AuthContext } from 'my-contexts/AuthContext';
import { Routes } from 'my-routes/portal';
import { RoutesService } from 'my-routes';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useContext(AuthContext);

    return (
        <Route
            {...rest}
                render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: RoutesService.getPath('ADMIN', 'AUTH_LOGIN'),
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
};

const RenderRoutes = () => {
    let xhtml = null;
    if (Routes.length) {
        xhtml = Routes.map((route, index) => {
            if (route.auth) {
                return (
                    <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.component} />
                )
            } else {
                return (
                    <Route key={index} exact={route.exact} path={route.path} component={route.component} />
                )
            }
        })
    }

    return <Switch>{xhtml}</Switch>
}

export default RenderRoutes;

