import { FETCH_SERVICE_BEGIN, FETCH_SERVICE_SUCCESS, FETCH_SERVICE_FAILURE } from 'my-actions/service';

const initialState = {
    pending: false,
    items: [],
    error: null
}

const service = (state = initialState, action) => {
    switch(action.type){
        case FETCH_SERVICE_BEGIN:
            return {
                ...state,
                pending: true
            }
        case FETCH_SERVICE_SUCCESS:
            return {
                ...state,
                pending: false,
                items: action.payload
            }
        case FETCH_SERVICE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
			return state;
	}
}

export default service;