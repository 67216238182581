import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ListFilter extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className=" icon-layers font-red" />
                                <span className="caption-subject font-red sbold uppercase">News Management</span>
                            </div>
                        </div>
                        <div className="portlet-body form">
                            <form autoComplete="off" className="form-horizontal">
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group form-md-line-input">
                                                <label className="col-md-2 control-label">Title</label>
                                                <div className="col-md-8">
                                                    <input type="text" name="title" className="form-control" />
                                                    <div className="form-control-focus"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-md-line-input">
                                                <label className="col-md-2 control-label">Language</label>
                                                <div className="col-md-8">
                                                    <select className="form-control" name="language_code">
                                                        <option />
                                                    </select>
                                                    <div className="form-control-focus"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group form-md-line-input">
                                                <label className="col-md-2 control-label">Category</label>
                                                <div className="col-md-8">
                                                    <select className="form-control" name="news_category_id">
                                                        <option />
                                                    </select>
                                                    <div className="form-control-focus"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-md-line-input">
                                                <label className="control-label col-md-2">Feature</label>
                                                <div className="col-md-8">
                                                    <div className="md-radio-inline">
                                                        <div className="md-radio">
                                                            <input type="radio" id="checkbox1_6" name="radio1" defaultValue={1} className="md-radiobtn" />
                                                            <label htmlFor="checkbox1_6">
                                                                <span />
                                                                <span className="check" />
                                                                <span className="box" /> No </label>
                                                        </div>
                                                        <div className="md-radio">
                                                            <input type="radio" id="checkbox1_7" name="radio1" defaultValue={2} className="md-radiobtn" />
                                                            <label htmlFor="checkbox1_7">
                                                                <span />
                                                                <span className="check" />
                                                                <span className="box" /> Yes </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group form-md-line-input">
                                                <label className="control-label col-md-2">Feature Slide </label>
                                                <div className="col-md-8">
                                                    <div className="md-radio-inline">
                                                        <div className="md-radio">
                                                            <input type="radio" id="checkbox1_6" name="radio1" defaultValue={1} className="md-radiobtn" />
                                                            <label htmlFor="checkbox1_6">
                                                                <span />
                                                                <span className="check" />
                                                                <span className="box" /> No 
                                                            </label>
                                                        </div>
                                                        <div className="md-radio">
                                                            <input type="radio" id="checkbox1_7" name="radio1" defaultValue={2} className="md-radiobtn" />
                                                            <label htmlFor="checkbox1_7">
                                                                <span />
                                                                <span className="check" />
                                                                <span className="box" /> Yes 
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <Link
                                                to="/news/form/create"
                                                className="btn green"
                                            ><i className="fa fa-plus" /> Create</Link>
                                            <button className="btn blue"><i className="fa fa-search" /> Search</button>
                                            <button className="btn default"><i className="fa fa-refresh" /> Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListFilter;