import React from 'react';

import { actFetchMockData, actfecthMockDataPure } from 'my-actions/mock';
import { BlogService } from 'my-services/repositories';

const DashboardView = () => {

    const handleFetchData = () => {
        // this.props.fetchMockData();

        let response = BlogService.getByID(1).then(res => {
            console.log(res);
        })
        .catch(error => {
            console.log('errr');
        });

        console.log(response);

        response = BlogService.getByID(1);

        console.log(response);
        console.log("Fetch Data");
    }

    return (
        <div>
            <h1>Dash Board Component</h1>
            <button onClick={() => handleFetchData} type="button">Fetch Data</button>
        </div>
    );
}

export default DashboardView;
