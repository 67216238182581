import React from 'react';
import { Link } from 'react-router-dom';

import { NavbarExtend, NavbarMenu } from 'my-components/portal/partials';
import { RoutesService } from 'my-routes';

const Header = () => {
    return (
        <div className="header">
            <nav className="navbar navbar-default navbar-fixed-top" id="global-nav">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#headerNavbar">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="menu-2 pull-right" data-toggle="modal" data-target="#menu-2"><img src="img/icon-cogs.png" alt="cogs"/></a>
                        <Link to={RoutesService.getPath('PORTAL', 'DASH_BOARD')} className="navbar-brand">
                            <img src="/portal/img/logo_2.png" alt="logo"/>
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse navbar-right" id="headerNavbar">
                        <NavbarMenu />
                        <NavbarExtend />
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;