import { AppConfig } from 'my-constants';
import { HttpService } from 'my-utils/core';

class GeneralService {
    serviceUrl = `${AppConfig.API_URL}/system/general`;

    apply(params){
        return HttpService.post(`${this.serviceUrl}/apply`, params).then(res => {
            return res;
        });
    }

    contact(params){
        return HttpService.post(`${this.serviceUrl}/contact`, params).then(res => {
            return res;
        });
    }
}

export default new GeneralService()