import React from 'react';

import { List as ListHeadline } from 'my-containers/admin/headline';
import { List } from 'my-containers/admin/service';

const ListView = () => {
	// const { location: { pathname } } = this.props;
	// const regexp = new RegExp(/^\/admin\/(.*?)\/.*?$/, 'g');
	// const controller = pathname.replace(regexp, '$1');

	return (
		<>
			<ListHeadline category="service" />
			<List/>
		</>
	);
}

export default ListView;