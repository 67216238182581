import React from 'react';

import { BaseComponent } from 'my-utils/core';
import { FormContent } from 'my-components/admin/news'

class FormView extends BaseComponent {

    submit(values) {
        console.log(values);

        let params = '';
        params += encodeURIComponent('email') + '=' + encodeURIComponent('captain-america@marvel.com') + '&';
        params += encodeURIComponent('password') + '=' + encodeURIComponent('123');

        let headers = {
            'Accept': 'application/json',
            // 'Authorization': 'Bearer ',
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        if(localStorage.getItem('api_token')){
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('api_token');
            // console.log(localStorage.getItem('api_token'));
        }

        let options = {
            method: 'POST',
            headers: headers,
            body: params
        };

        fetch('http://localhost/vision-system/api/public/api/product/undefined', options)
            .then(response => response.json())
            .then(res => {
                    console.log(res);
                })
    }

    render() {
        return (
            <div className="portlet light bordered">
                <div className="portlet-title">
                    <div className="caption">
                        <i className=" icon-layers font-red" />
                        <span className="caption-subject font-red sbold uppercase">News Management</span>
                    </div>
                </div>
                <div className="portlet-body form">
                    <div className="tabable-line boxless margin-bottom-20">
                        <ul className="nav nav-tabs">
                            <li className="active">
                                <a href="#tab_vi" className="link-tab" data-toggle="tab" data-lang="vi" aria-expanded="true"> Tiếng Việt </a>
                            </li>
                            <li>
                                <a href="#tab_en" className="link-tab" data-toggle="tab" data-lang="en" aria-expanded="false"> English </a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade active in" id="tab_vi">
                            <FormContent key="vn" title="Title_VN" name="form1" onSubmit={this.submit} form={'FormView1'} />
                        </div>
                        <div className="tab-pane fade" id="tab_en">
                            <FormContent key="en" title="Title_EN" name="form2" onSubmit={this.submit} form={'FormView2'} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormView;