import React, { useLayoutEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cloneDeep, findIndex } from 'lodash';

import { AppConfig } from 'my-constants';
import { renderTextField, renderDropzone } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { Preview } from 'my-components/admin/partials';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';

const Form = props => {
	const [ uploader, setUploader ] = useState([]);
	const { MAX_FILESIZE, UPLOAD_FILE_EXTENSION } = AppConfig;
	const { category, change, handleSubmit, method, reset, submitting, untouch } = props;
	const validate = (method === 'update' && uploader.length) ? [] : [rule.required];
	const ext = UPLOAD_FILE_EXTENSION.join();

	let route_name = '';
	if(category){
		let name = category;
		if(name === 'about-us') name = 'staff';
		route_name = name.toUpperCase() + '_LIST';
	}

	const item = useSelector(state => state.common.data, shallowEqual);

	useLayoutEffect(() => {
		if(item?.filename && item?.filepath){
			let queue = [];
			const file = {
				name: item.filename,
				path: item.filepath,
			}

			queue.push(file);
			setUploader(queue);
		}

		return () => setUploader([]);
	}, [item]);

	const handleFile = (files, accepted, limit) => {
		if(!accepted){
			for(let k in files){
				let file = files[k];
				// invalid format
				if(UPLOAD_FILE_EXTENSION.indexOf(file.type) === -1){
					Notification.error(file.name + "'s format is invalid");
				}
				// invalid filesize
				if(file.size > MAX_FILESIZE){
					Notification.error(file.name + "'s size is invalid");
				}
			}
		}else{
			let clone_uploader = cloneDeep(uploader);
			if(limit > clone_uploader.length){
				for(let k in files){
					clone_uploader.push(files[k]);
				}
			}else{
				let remain = limit - clone_uploader.length;
				if(remain){
					for(let k in files){
						let file = files[k];
						if(findIndex(clone_uploader, file) === -1){
							if(remain){
								clone_uploader.push(files[k]);
								remain--;
							}
						}
					}
				}else{
					Notification.error(`You can only upload maximum ${limit} files.`);
				}
			}

			setUploader(clone_uploader);
		}
	}

	const setInputFile = files => {
		setUploader(files);
		change('file_attach', '');
		untouch('file_attach');
	}

	const onReset = () => {
		let queue = [];
		reset();
		if(item.id && item.filename && item.filepath){
			const file = {
				name: item.filename,
				path: item.filepath,
			}
			queue.push(file);
		}

		setUploader(queue);
	}

	return (
		<form className="form-horizontal" onSubmit={handleSubmit}>
			<div className="form-body">
				<div className="form-group">
					<label className="control-label col-md-2">Title <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="title" component={renderTextField} fullWidth={true} validate={[rule.required]} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Description <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="description" multiline={true} rows="3" component={renderTextField} fullWidth={true} validate={[rule.required]} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Image <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="file_attach" component={renderDropzone} multiple={false} accept={ext} maxSize={AppConfig.MAX_FILESIZE} onAccepted={handleFile} validate={validate} />
						<Preview files={uploader} setInputFile={files => setInputFile(files)} />
					</div>
				</div>
			</div>
			<div className="form-actions">
				<div className="row">
					<div className="col-md-offset-5 col-md-7">
						<button className="btn green" type="submit" disabled={submitting}><i className="fa fa-save" /> Save</button>
						<button className="btn blue" type="button" disabled={submitting} onClick={onReset}><i className="fa fa-refresh" /> Clear</button>
						<Link to={ RoutesService.getPath('ADMIN', route_name) } className="btn default">
							<i className="fa fa-arrow-left" /> Back
						</Link>
					</div>
				</div>
			</div>
		</form>
	);

}

const FormContent = reduxForm({enableReinitialize: true})(Form);

export default FormContent;