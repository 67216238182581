import React from 'react';

import { ListData } from 'my-components/admin/headline';

const List = props => {
    const { category } = props;
    return (
        <section>
            <ListData category={category} />
        </section>
    )
}

export default List;