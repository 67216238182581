/*================================
 * App System Configuration
 *================================*/
export const DEBUG_ENABLED = true;

//API Config Variable
// export const API_SERVER = "http://localhost/homepage/api";
// export const API_SERVER = "https://stg-homepage-api.vision-vietnam.com";
export const API_SERVER = "https://homepage-api.vision-vietnam.com";
export const API_URL = API_SERVER + "/public/api";
export const API_AUTH_USER = "halfdefense";
export const API_AUTH_PASS = "$jSZ9y})haSLp>LB";

//System Config Variable
// export const BASE_HREF = 'http://localhost:3000';
// export const BASE_HREF = "https://stg-homepage.vision-vietnam.com";
export const BASE_HREF = 'https://vision-vietnam.com';
export const SESSION_EXPIRED = 8;
export const DEFAULT_LANG = "vi";
export const DEFAULT_TIMEZONE = "Asia/Tokyo";
export const FORMAT_DATE = "YYYY/MM/DD";
export const FORMAT_MONTH = "YYYY/MM";
export const FORMAT_DATETIME = "YYYY/MM/DD HH:mm:ss";
export const FORMAT_DATEMIN = "YYYY/MM/DD HH:mm";
export const FORMAT_POSTED_DATE = 'D MMM YYYY';
export const FORMAT_LOCALE = "en-Us";
export const HEADTITLE = 'Vision Vietnam Homepage';
export const MAX_FILESIZE = 10000000 //bytes
export const UPLOAD_FILE_EXTENSION = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const LIMIT_ITEM_PER_PAGE = 8;
export const PAGE_RANGE_DISPLAYED = 5;

export const LANGUAGES = [{ 'code':'vi', 'name':'Tiếng Việt', 'src':'/portal/img/flag-vie.png' },
                            /*{ 'code':'en', 'name':'English', 'src':'/portal/img/flag-eng.png' },
                            { 'code':'ja', 'name':'日本語', 'src':'/portal/img/flag-jap.png' }*/
                        ];