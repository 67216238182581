import { AppConfig } from 'my-constants';
import { HttpService } from 'my-utils/core';

class BaseService {

    serviceUrl = AppConfig.API_URL;

    /*
    * @content: set value for serviceUrl variable
    * @param: url: string
    */
    setServiceUrl(url) {
        this.serviceUrl = url;
    }

    getCancelTokenSource(){
        return HttpService.getCancelTokenSource();
    }

    /*
    * @content: get 1 record data
    * @param: id: number
    */
    getByID(id, cancelToken = {}) {
        return HttpService.get(`${this.serviceUrl}${id}`, {}, cancelToken);
    }

    /*
    * @content: get all record data
    * @param: options: object
    *           + queryString: query string url
    *         CancelToken: object
    */
    getAll(options = {}, cancelToken = {}) {
        return HttpService.get(this.serviceUrl, options, cancelToken);
    }

    save(data = [], cancelToken = {}) {
        return HttpService.post(this.serviceUrl, data, cancelToken);
    }

    update(id, data = [], cancelToken = {}) {
        return HttpService.post(`${this.serviceUrl}${id}`, data, cancelToken);
    }

    delete(id, queryParams = [], cancelToken = {}) {
        return HttpService.delete(`${this.serviceUrl}${id}`, queryParams, cancelToken);
    }
}

export default BaseService;
