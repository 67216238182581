import React from 'react';

import { ListFilter, ListData } from 'my-components/admin/blog-category';

const List = () => {
    return (
        <section>
            <ListFilter />
            <ListData />
        </section>
    )
}

export default List;