import React from 'react';

import { List as ListHeadline } from 'my-containers/admin/headline';
import { List } from 'my-containers/admin/staff';

const ListView = () => {
	return (
		<>
			<ListHeadline key="about-us" category="about-us" />
			<ListHeadline key="staff" category="staff" />
			<List/>
		</>
	);
}

export default ListView;