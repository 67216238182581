export const FETCH_DATA_MOCK = 'FETCH_DATA_MOCK';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_PHOTO_A = 'FETCH_PHOTO_A';
export const FETCH_PHOTO_B = 'FETCH_PHOTO_B';

// authenticated
export const AUTH_ADD_DATA      = 'AUTH_ADD_DATA';
export const INIT_DATA          = 'INIT_DATA';
export const UPLOADER_DATA      = 'UPLOADER_DATA';
export const SEARCH_DATA        = 'SEARCH_DATA';
export const UPLOAD_FILE        = 'UPLOAD_FILE';
