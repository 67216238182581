import React from 'react';

import { AppConfig } from 'my-constants';
import AdminView from 'my-views/admin/AppView';
import PortalView from 'my-views/portal/AppView';

const RenderComponent = () => {
    // let component = null;
    // let path = window.location.pathname;
    const language_code = localStorage.getItem('_l') || AppConfig.DEFAULT_LANG;
    localStorage.setItem('_l', language_code);

    // component = path.match(/^\/admin.*/i) ? (<AdminView/>) : (<PortalView/>)
    let component = (<PortalView/>);
    return component;
}

const App = () => {
    return (<RenderComponent/>);
}

export default App;