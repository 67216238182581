import React from 'react';

import { ListFilter, ListData } from 'my-components/admin/news';

const ListView = () => {
    return (
        <section>
            <ListFilter />
            <ListData />
        </section>
    );
}

export default ListView;