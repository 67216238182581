import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import 'my-public/admin/pages/css/login.min.css';
import { RenderRoutes } from 'my-routes/admin';
import { Loading } from 'my-components/admin/layouts';

const theme = createMuiTheme({
	overrides: {
		MuiInput: {
			underline: {
				'&:after': { borderBottom: '2px solid #36c6d3' },
				'&:hover:not($disabled):not($error):not($focused):before': { borderBottom: '2px solid #36c6d3' },
			},
		},
	},
	palette: {
		primary: { main: '#36c6d3', contrastText: 'white' },
	},
	typography: {
		useNextVariants: true
	}
});

const AuthenticationLayout = () => {
	return (
		<MuiThemeProvider theme={theme}>
			<RenderRoutes />
			<Loading />
		</MuiThemeProvider>
	)
}

export default AuthenticationLayout;
