import { ActionType } from 'my-constants';
import { HttpService } from 'my-utils/core';

export const actFetchMockData = () => {
    let apiUrl = 'https://jsonplaceholder.typicode.com/photos?albumId=1';
    return (dispatch) => {
        return HttpService.get(apiUrl)
            .then(response => {
                dispatch({
                    type: ActionType.FETCH_DATA_MOCK,
                    payload: response.data
                })
            })
            .catch(error => {

            })
    }
}

export const actfecthMockDataPure = (payload) => {
    return {
        type: ActionType.FETCH_DATA_MOCK,
        payload
    }
}