import $ from 'jquery';

class Helper {
    //Show global loading
    showLoading = () => {
        $('.wrap-loader').show();
    }

    //Hide global loading
    hideLoading = () => {
        $('.wrap-loader').delay(200).hide(0);
    }

    scrollTo = (element = 'body', delay = 800) => {
        let position = $(element).offset().top
        $('html, body').animate({ scrollTop: position }, delay);
    }

    descriptionPosition = element => {
        let check_img_cover = $(element).children(".img-cover").find("img");

        $(check_img_cover).each(function () {
            let imgCoverHeight = $(this).height();
            let descriptionPosition = $(this).parents(element).next(".description");
            let descriptionHeight = descriptionPosition.outerHeight();

            if (imgCoverHeight > descriptionHeight) {
                $(descriptionPosition).css("cssText", "margin-top: -" + (descriptionHeight * 1 / 2) + "px;");
            } else {
                $(descriptionPosition).css("cssText", "margin-top: -" + (imgCoverHeight * 1 / 3) + "px;");
            }
        });
    }

    blogSearchInput = () => {
        if ($(window).width() > 767) {
            $(".blog-search-input input").on("focusin", function () {
                $(".blog-category-horizontal>ul").hide();
                $(".blog-search-input").css("cssText", "width: 100%;");
                $(".input-group-btn-search>a>i").removeClass("fa-search");
                $(".input-group-btn-search>a>i").addClass("fa-times");

                $(".blog-list").hide();
                $(".blog-search-result").show();
            });
            $(".blog-search-input input").on("blur", function () {
                $(".blog-category-horizontal>ul").show();
                if ($(window).width() > 1024) {
                    $(".blog-search-input").css("cssText", "width: 25%;");
                } else if ($(window).width() > 767 && $(window).width() < 1025) {
                    $(".blog-search-input").css("cssText", "width: 50%;");
                }

                $(".input-group-btn-search>a>i").removeClass("fa-times");
                $(".input-group-btn-search>a>i").addClass("fa-search");
                $(".blog-list").show();
                $(".blog-search-result").hide();
            });
        } else if ($(window).width() < 768) {
            var flag = 0;

            if (flag === 0) {
                $(".input-group-btn-search>a>i").on("click", function () {
                    $(this).parents(".blog-search-input").find("input").css("cssText", "display: block;");
                    $(this).parents(".blog-search-input").css("cssText", "width: 100%;");
                    $(".blog-category-horizontal>ul").hide();
                    $(".input-group-btn-search>a>i").removeClass("fa-search");
                    $(".input-group-btn-search>a>i").addClass("fa-times");
                    flag = 1;
                });
            }

            if (flag === 1) {
                $(".input-group-btn-search>a>i").on("click", function () {
                    alert();
                    $(this).parents(".blog-search-input").find("input").css("cssText", "display: none;");
                    $(this).parents(".blog-search-input").css("cssText", "width: 1px;");
                    $(".blog-category-horizontal>ul").show();
                    $(".input-group-btn-search>a>i").addClass("fa-search");
                    $(".input-group-btn-search>a>i").removeClass("fa-times");
                    flag = 0;
                });

            }
        }
    }

    blogHover = () => {
        if ($(window).width() > 1024) {
            $("a.blog-list-hover").mouseover(function () {
                $(this).find(".btn-more").stop().show(100);
            });

            $("a.blog-list-hover").mouseout(function () {
                $(this).find(".btn-more").stop().hide(100);
            });
        }
    }
}

export default new Helper();