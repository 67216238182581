import { StaffService } from 'my-services/repositories';

export const FETCH_STAFF_BEGIN = 'FETCH_STAFF_BEGIN';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILURE = 'FETCH_STAFF_FAILURE';

export function fetchStaffAll(params, token) {
    return dispatch => {
        dispatch(fetchBegin());
        return StaffService.getAll(params, token).then(
            res => {
                dispatch(fetchSuccess(res.data));
                return res.data;
            },
            error => { dispatch(fetchFailure(error)) }
        );
    }
}

const fetchBegin = () => ({ type: FETCH_STAFF_BEGIN });
const fetchSuccess = items => ({ type: FETCH_STAFF_SUCCESS, payload: items });
const fetchFailure = error => ({ type: FETCH_STAFF_FAILURE, error: error });