import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Field , reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';

import { AuthService } from 'my-services/system';
import { AuthContext } from 'my-contexts/AuthContext';
import { RoutesService } from 'my-routes';
import { renderTextField } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { Notification } from 'my-utils';

const Form = props => {
	const history = useHistory();
	const { login, isLoggedIn } = useContext(AuthContext);
	const { handleSubmit, submitting } = props;

	useEffect(() => {
		//Redirect dashboard if valid session
		if(isLoggedIn) {
			history.push(RoutesService.getPath('ADMIN', 'DASH_BOARD'));
		}
	}, [])

	const handleClick = e => {
		e.preventDefault();
		Notification.success('This is a test');
		history.replace(RoutesService.getPath('ADMIN', 'DASH_BOARD'));
	}

	const onLogin = params => {
		let formData = new FormData();
		for(let key in params){
			formData.append(key,params[key]);
		}

		AuthService.login(formData).then(res => {
			if (res.code === 200) {
				login(res.data);
				history.replace(RoutesService.getPath('ADMIN', 'DASH_BOARD'));
			} else {
				Notification.error('Username or password is not correct!');
			}
		})
	}

	return (
		<div className="login" style={{ backgroundColor : 'transparent' }}>
			<div className="logo">
				<a href="#" onClick={handleClick}>Hit Me To Redirect!!!!
					<img src="" alt="" />
				</a>
			</div>

			<div className="content">
				<form className="form-horizontal" onSubmit={handleSubmit(values => onLogin(values))}>
					<h3 className="form-title font-green">Control Panel</h3>
					<div className="form-group">
						<Field name="email" component={renderTextField} label="Email" fullWidth={true} validate={[rule.required, rule.email]} />
					</div>
					<div className="form-group">
						<Field type='password' name="password" component={renderTextField} label="Password" fullWidth={true} validate={[rule.required]} />
					</div>
					<div className="form-actions" style={{borderBottom : 'none', textAlign: 'center'}}>
						<Button variant="contained" color="primary" type="submit" disabled={submitting}>Login</Button>
					</div>
				</form>
			</div>
		</div>
	);
}

const FormLogin = reduxForm({ form: 'FormLogin' })(Form);

export default FormLogin;

