import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { defer } from 'rxjs';

import { AppConfig } from 'my-constants';
import { FormContent } from 'my-components/admin/headline';
import { Navtab } from 'my-components/admin/partials';
import { HeadlineService } from 'my-services/repositories';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';
import { init as initAction } from 'my-actions/common';

const Form = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { id, method } = useParams();
	const [ category, setCategory ] = useState(null);
	const [ languageCode, setLanguageCode ] = useState('vi');
	const [ model, setModel ] = useState({ category: category, status: 'active' });
	const { LANGUAGES } = AppConfig;

	useEffect(() => {
		const { state } = location;
		setCategory(state.category);
		dispatch(initAction({  ...model, category: category }));

		if(method === 'update'){
			HeadlineService.getByID(id).then(res => {
				if(res.success){
					setModel(res.data);
					dispatch(initAction(res.data));
				}
			});
		}
	}, [method, id, location.state])

	const onSave = values => {
		let route_name = '';
		if(category){
			let name = category;
			if(name === 'about-us') name = 'staff';
			route_name = name.toUpperCase() + '_LIST';
		}

		let formData = new FormData();
		let uploader = values['file_attach'];

		for(let key in uploader){
			let upload = uploader[key];
			// //Khoa Nguyen - 2017-03-13 - fix issue when attach file on firefox
			let objUpload = new Blob([upload]);
			formData.append('file_attach', objUpload, upload.name);
		}

		for(let key in values){
			if(key === 'file_attach') continue;
			formData.append(key, values[key]);
		}
		formData.append('language_code', languageCode);

		const headline$ = defer(() => (method === 'create') ? HeadlineService.save(formData) : HeadlineService.update(id, formData));
		headline$.subscribe(res => {
			if(res.success){
				Notification.success('Saved!');
				history.push(RoutesService.getPath('ADMIN', route_name));
			}
		});
	}

	let subheadline = category ? category.replace('-', ' ') : '';

	return (
		<div className="portlet light bordered">
			<div className="portlet-title">
				<div className="caption">
					<i className="icon-layers font-red" />
					<span className="caption-subject font-red sbold uppercase">Headline <i className="fa fa-arrow-right"></i> {subheadline}</span>
				</div>
			</div>
			<div className="portlet-body form">
				<div className="tabable-line boxless margin-bottom-20">
					<Navtab language_code={languageCode} onClickTab={code => setLanguageCode(code)} />
				</div>
				<div className="tab-content">
					{
						LANGUAGES.map((item, idx) => {
							let no = idx+1;
							return (
								<div className={"tab-pane fade " + (languageCode === item.code ? 'active in' : '')} id={"tab_" + item.code} key={idx}>
									<FormContent key={item.code} initialValues={model} name={`form${no}`} method={method} onSubmit={onSave} form={`FormView${no}`} category={category}/>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default Form;