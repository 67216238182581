import { Component } from 'react';

import { AuthService } from 'my-services/system';
import { RoutesService } from 'my-routes';

class BaseComponent extends Component {

    currentPath = '';

    constructor(props) {
        super(props);

        //Interval check Valid
        this.checkInterval = setInterval( _ => {
            this.onChangePath();
        }, 3000);
    }

    /*
    |--------------------------------------------------------------------------
    | Handle on change path
    |--------------------------------------------------------------------------
    */
    onChangePath = () => {
        try {
            const path = this.props.location.pathname;
            // console.log(this.currentPath);
            // if(this.currentPath !== path) {
                this.currentPath = path;
                this.handleSession(path);
            // }
        } catch (error) { }
    }

    /*
    |--------------------------------------------------------------------------
    | Check Session Is Valid
    |--------------------------------------------------------------------------
    */
    handleSession = (path) => {
        if (!path.match(/^\/admin\/auth\/login.*/i)) {
    		if(!AuthService.isSessionValid()) {
                try {
                    AuthService.logout();
                } catch(error) {}
                this.props.history.push(RoutesService.getPath('ADMIN', 'AUTH_LOGIN'));
                window.location.reload();
            }
		}
    }

    /*
    |--------------------------------------------------------------------------
    | Executed after the first render only on the client side. This is where AJAX requests and DOM or state updates should occur
    |--------------------------------------------------------------------------
    */
    componentDidMount() {

    }

    /*
    |--------------------------------------------------------------------------
    | Should return true or false value. This will determine if the component will be updated or not
    |--------------------------------------------------------------------------
    */
    shouldComponentUpdate(newProps, newState) {
        return true;
    }

    /*
    |--------------------------------------------------------------------------
    | Called just after re-rendering
    |--------------------------------------------------------------------------
    */
    componentDidUpdate(prevProps, prevState) {
    }

    /*
    |--------------------------------------------------------------------------
    | Called after the component is unmounted from the dom
    |--------------------------------------------------------------------------
    */
    componentWillUnmount() {

    }
}

export default BaseComponent;