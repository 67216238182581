import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Modal from 'react-modal';
import { findIndex } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { cloneDeep } from 'lodash';

import { AppConfig } from 'my-constants';
import { renderDropzone, renderSelectField, renderTextField } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { Preview } from 'my-components/admin/partials';
import { Notification } from 'my-utils';

const customStyles = {
    content : {
        top					: '50%',
        left				: '50%',
        right				: '0%',
        bottom				: '0%',
		marginRight			: '-50%',
		padding				: '0px',
		transform			: 'translate(-50%, -50%)',
		maxHeight			: '1000px',
		maxWidth			: '750px',
        overlfow			: 'scroll'
	},
	overlay: {
		backgroundColor		: 'rgba(0, 0, 0, 0.8)',
		zIndex				: 10
	}
};

const file_attach_extension = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

const Form = props => {
    const { change, el, handleSubmit, onHide, reset, submitting, show, untouch } = props;
    const { MAX_FILESIZE } = AppConfig;
    const [ uploader, setUploader ] = useState([]);
    const ext = file_attach_extension.join();

    const items = useSelector(state => state.recruitment.items, shallowEqual);

    useEffect(() => {
        if(!show){
            onCloseModal();
            document.body.style.overflow = '';
        }
        show && (document.body.style.overflow = 'hidden');
    }, [show]);

    const handleFile = (files, accepted, limit) => {
        if(!accepted){
            for(let k in files){
                let file = files[k];
                // invalid format
                if(file_attach_extension.indexOf(file.type) === -1){
                    Notification.error(file.name + "'s format is invalid");
                }
                // invalid filesize
                if(file.size > AppConfig.MAX_FILESIZE){
                    Notification.error(file.name + "'s size is invalid");
                }
            }
        }else{
            let clone_uploader = cloneDeep(uploader);
			if(limit > clone_uploader.length){
				for(let k in files){
					clone_uploader.push(files[k]);
				}
			}else{
				let remain = limit - clone_uploader.length;
				if(remain){
					for(let k in files){
						let file = files[k];
						if(findIndex(clone_uploader, file) === -1){
							if(remain){
								clone_uploader.push(files[k]);
								remain--;
							}
						}
					}
				}else{
					Notification.error(`You can only upload maximum ${limit} files.`);
				}
			}

			setUploader(clone_uploader);
        }
    }

    const setInputFile = files => {
		setUploader(files);
		change('file_attach', '');
		untouch('file_attach');
	}

    const onCloseModal = () => {
        setUploader([]);
        reset();
        onHide();
    }

    return (
        <Modal
            isOpen={show}
            parentSelector={el}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            onRequestClose={onCloseModal}
            style={customStyles}
            contentLabel="Job Application Modal"
            className="modal-content"
        >
            <div className="modal-header career-header">
                <button type="button" className="close" onClick={onCloseModal}>
                    <i className="fas fa-times"></i>
                </button>
                <div>Job Application</div>
            </div>
            <div className="modal-body">
                <form className="form-horizontal" onSubmit={handleSubmit} >
                    <div className="form-body">
                        <div className="form-group">
                            <label className="control-label col-md-2">Name <span className="required">*</span></label>
                            <div className="col-md-10">
                                <Field name="name" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-md-2">Phone <span className="required">*</span></label>
                            <div className="col-md-10">
                                <Field name="phone" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-md-2">Email <span className="required">*</span></label>
                            <div className="col-md-10">
                                <Field name="email" component={renderTextField} fullWidth={true} validate={[rule.required, rule.email]} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-md-2">Position <span className="required">*</span></label>
                            <div className="col-md-10">
                                <Field name="position" component={renderSelectField} fullWidth={true} validate={[rule.required]} >
                                    <MenuItem value="">None</MenuItem>
                                    {
                                        items.map((item, idx) => {
                                            return (
                                                <MenuItem key={idx} value={item.name}>{item.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Field>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-md-2">CV Attached <span className="required">*</span></label>
                            <div className="col-md-10">
                                <Field name="file_attach" component={renderDropzone} multiple={true} accept={ext} maxSize={MAX_FILESIZE} onAccepted={handleFile} validate={[rule.required]} />
                                <Preview files={uploader} setInputFile={files => setInputFile(files)}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-actions">
                        <div className="row">
                            <div className="col-md-offset-5 col-md-7">
                                <button className="btn green" type="submit" disabled={submitting}><i className="fa fa-save" /> Send</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

const JobApplication = reduxForm({ enableReinitialize: true })(Form);

export default JobApplication;