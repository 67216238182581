import { ServiceService } from 'my-services/repositories';

export const FETCH_SERVICE_BEGIN = 'FETCH_SERVICE_BEGIN';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_FAILURE = 'FETCH_SERVICE_FAILURE';

export function fetchServiceAll(params, token) {
    return dispatch => {
        dispatch(fetchBegin());
        return ServiceService.getAll(params, token).then(
            res => {
                dispatch(fetchSuccess(res.data));
                return res.data;
            },
            error => { dispatch(fetchFailure(error)) }
        )
    }
}

const fetchBegin = () => ({ type: FETCH_SERVICE_BEGIN });
const fetchSuccess = items => ({ type: FETCH_SERVICE_SUCCESS, payload: items });
const fetchFailure = error => ({ type: FETCH_SERVICE_FAILURE, error: error });