import React from 'react';
import { of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap, catchError } from 'rxjs/operators';
import { Paper } from '@material-ui/core';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

import { PagingState, CustomPaging, SortingState, SelectionState, IntegratedSelection } from '@devexpress/dx-react-grid';
// IntegratedPaging
import { Grid, VirtualTable, Table, TableHeaderRow, PagingPanel, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import Loading from './Loading';

import { AppConfig } from 'my-constants';

const getRowsData = (data) => {
    return data;
};

class UIDatatables extends React.PureComponent {
    static propTypes = {
        columns: PropTypes.array.isRequired,
        url: PropTypes.string.isRequired,
        queryParams: PropTypes.object.isRequired,
        sorting: PropTypes.array.isRequired,
        sortingStateColumnExtensions: PropTypes.array,
        columnExtensions: PropTypes.array,
        handleRowsData: PropTypes.func,
        showSelection: PropTypes.bool,
        showSelectAll: PropTypes.bool,
        selectByRowClick: PropTypes.bool,
        reload: PropTypes.bool,
        reloaded: PropTypes.func
    };

    static defaultProps = {
        pageSize: 10,
        pageSizes: [5, 10, 15],
        currentPage: 0,
        sortingStateColumnExtensions: [],
        columnExtensions: [],
        // selection
        showSelection: false,
        showSelectAll: true,
        selectByRowClick: false,
        reload: false,

        handleRowsData: data => {
            return getRowsData(data)
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            columns: props.columns,
            rows: [],
            selection: [],
            totalCount: 1000,
            pageSize: props.pageSize,
            pageSizes: props.pageSizes,
            currentPage: props.currentPage,
            sorting: props.sorting,
            loading: false,
            sortingStateColumnExtensions: props.sortingStateColumnExtensions,
            columnExtensions: props.columnExtensions,
            handleRowsData: props.handleRowsData
        };

        const { API_AUTH_USER, API_AUTH_PASS } = AppConfig;
        this.authorization = 'Basic ' + btoa(`${API_AUTH_USER}:${API_AUTH_PASS}`)
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { queryParams, reload, reloaded } = this.props;

        !isEqual(queryParams, prevProps.queryParams) && this.setState({ currentPage: 0 });

        this.loadData();
        if(reload){
            this.lastQuery = '';
            this.loadData();
            reloaded();
        }
    }

    changeCurrentPage(currentPage) {
        this.setState({
            loading: true,
            currentPage,
            selection: []
        });
    }

    changePageSize(pageSize) {
        const totalPages = Math.ceil(this.state.totalCount / pageSize);
        const currentPage = Math.min(this.state.currentPage, totalPages - 1);

        this.setState({
            loading: true,
            pageSize,
            currentPage,
            selection: []
        });
    }

    changeSorting(sorting) {
        this.setState({
            loading: true,
            sorting,
        });
    }

    changeSelection(selection) {
        this.setState({selection})
    }

    queryString = () =>  {
        const { pageSize, currentPage } = this.state;
        let url = `${this.props.url}?`;
        let queryParams = this.props.queryParams;

        if(!isEmpty(queryParams)){
            let params = {};
            for(let key in queryParams){
                if(queryParams[key]){
                    params[key] = queryParams[key];
                }
            }
            url += queryString.stringify(params);
        }

        if (this.state.sorting) {
            const columnSorting = this.state.sorting[0];
            if (columnSorting) {
                const sortDirectionString = columnSorting.direction === 'desc' ? 'desc' : 'asc';
                url = `${url}&sort[${columnSorting.columnName}]=${sortDirectionString}`;
            }
        }

        url = `${url}&take=${pageSize}&skip=${pageSize * currentPage}`;

        return url;
    }

    loadData = () => {
        const queryString = this.queryString();
        this.setState({ loading: true });
        // const api$ = fromFetch(queryString, { headers: new Headers({ 'Authorization': this.authorization }) }).pipe(
        //     switchMap(response => {
        //         if(response.ok){
        //             return response.json();
        //         }else{
        //             return of({error: true, message: `Error ${response.status}`});
        //         }
        //     }),
        //     catchError(error => {
        //         console.log(error);
        //         return of({  });
        //     })
        // );

        // api$.subscribe({
        //     next: res => {
        //         const rows = res.data.length ? res.data : [];
        //         const totalCount = res.total || 0;
        //         this.setState({
        //             rows: rows,
        //             totalCount: totalCount,
        //             loading: false
        //         });
        //     },
        //     complete: () => console.log('Done')
        // })

        if (queryString === this.lastQuery) {
            this.setState({ loading: false });
            return;
        }

        fetch(queryString, { headers: new Headers({ 'Authorization': this.authorization }) })
            .then(response => response.json())
            .then(res => {
                const rows = res.data.length ? res.data : [];
                const totalCount = res.total || 0;
                this.setState({
                    rows: rows,
                    totalCount: totalCount,
                    loading: false
                });
            })
            .catch(() => this.setState({ loading: false }));
        this.lastQuery = queryString;
    }

    render() {
        const { rows, columns, pageSize, pageSizes, currentPage, totalCount, sorting, loading, sortingStateColumnExtensions, columnExtensions, handleRowsData, selection } = this.state;
        const { showSelection, showSelectAll, selectByRowClick } = this.props;

        return (
            <Paper style={{ position: 'relative' }}>
                <Grid rows={handleRowsData(rows)} columns={columns}>
                    <SortingState
                        sorting={sorting}
                        columnExtensions={sortingStateColumnExtensions}
                        onSortingChange={sorting => this.changeSorting(sorting)}
                    />
                    {showSelection &&
                        <SelectionState
                            selection={selection}
                            onSelectionChange={selection => this.changeSelection(selection)}
                        />
                    }
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={currentPage => this.changeCurrentPage(currentPage)}
                        pageSize={pageSize}
                        onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                    />
                    <CustomPaging totalCount={totalCount} />

                    {/* <IntegratedPaging /> */}
                    {showSelection &&
                        <IntegratedSelection />
                    }

                    <VirtualTable />
                    <Table columnExtensions={columnExtensions}/>
                    <TableHeaderRow showSortingControls />
                    {showSelection && <TableSelection showSelectAll={showSelectAll} selectByRowClick={selectByRowClick} />}

                    <PagingPanel pageSizes={pageSizes} />
                    {loading && <Loading />}
                </Grid>
             </Paper>
        );
    }
}

export default UIDatatables;