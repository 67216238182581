import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { RoutesService } from 'my-routes';

const ListFilter = props => {
	const history = useHistory();
	const location = useLocation();
	const [ model, setModel ] = useState({ title: '', blog_category_id: '', priority: '', status: '' });
	const { items } = props;

	useEffect(() => {
		const { search } = location;
		const params = queryString.parse(search);
		setModel(params);
	}, [location.search])

	const onSearch = () => {
		history.replace({...location, search: queryString.stringify(model)});
	}

	const onClear = () => {
		setModel({ title: '', blog_category_id: '', priority: '', status: '' });
		history.replace({...location, search: ''});
	}

	return (
		<div className="row">
			<div className="col-md-12">
				<div className="portlet light bordered">
					<div className="portlet-title">
						<div className="caption">
							<i className=" icon-layers font-red" />
							<span className="caption-subject font-red sbold uppercase">Blog Management</span>
						</div>
					</div>
					<div className="portlet-body form">
						<form autoComplete="off" className="form-horizontal">
							<div className="form-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-group form-md-line-input">
											<label className="col-md-2 control-label">Title</label>
											<div className="col-md-8">
												<input type="text" name="title" className="form-control" value={model.title || ''} onChange={e => setModel({...model, title: e.target.value})}/>
												<div className="form-control-focus"></div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group form-md-line-input">
											<label className="col-md-2 control-label">Category</label>
											<div className="col-md-8">
												<select className="form-control" name="blog_category_id" value={model.blog_category_id || ''} onChange={e => setModel({...model, blog_category_id: e.target.value})}>
													<option value=""></option>
													{
														items.map((item, idx) => {
															return (<option key={idx} value={item.id}>{item.name}</option>)
														})
													}
												</select>
												<div className="form-control-focus"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group form-md-line-input">
											<label className="control-label col-md-2">Featured</label>
											<div className="col-md-8">
												<div className="md-radio-inline">
													<div className="md-radio">
														<input type="radio" id="checkbox1_6" name="priority" value="1" className="md-radiobtn"
															checked={model.priority === '1'}
															onChange={() => setModel({ ...model, priority: '1' })} />
														<label htmlFor="checkbox1_6">
															<span />
															<span className="check" />
															<span className="box" /> See first </label>
													</div>
													<div className="md-radio">
														<input type="radio" id="checkbox1_7" name="priority" value="0" className="md-radiobtn"
															checked={model.priority === '0'}
															onChange={() => setModel({ ...model, priority: '0' })} />
														<label htmlFor="checkbox1_7">
															<span />
															<span className="check" />
															<span className="box" /> Default </label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group form-md-line-input">
											<label className="control-label col-md-2">Status</label>
											<div className="col-md-8">
												<div className="md-radio-inline">
													<div className="md-radio">
														<input type="radio" id="checkbox1_8" name="status" value="active" className="md-radiobtn"
															checked={model.status === 'active'}
															onChange={() => setModel({ ...model, status: 'active' })} />
														<label htmlFor="checkbox1_8">
															<span />
															<span className="check" />
															<span className="box" /> Active
														</label>
													</div>
													<div className="md-radio">
														<input type="radio" id="checkbox1_9" name="status" value="inactive" className="md-radiobtn"
															checked={model.status === 'inactive'}
															onChange={() => setModel({ ...model, status: 'inactive' })} />
														<label htmlFor="checkbox1_9">
															<span />
															<span className="check" />
															<span className="box" /> InActive
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="form-actions">
								<div className="row">
									<div className="col-md-12 text-center">
										<Link to={ RoutesService.getPath('ADMIN', 'BLOG_FORM', { method: 'create' }) } className="btn green">
											<i className="fa fa-plus" /> Create
										</Link>
										<button type="button" className="btn blue" onClick={onSearch}><i className="fa fa-search" /> Search</button>
										<button type="button" className="btn default" onClick={onClear}><i className="fa fa-refresh" /> Clear</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ListFilter;