import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { RoutesService } from 'my-routes';

const ListFilter = () => {
    const history = useHistory();
	const location = useLocation();
	const [ name, setName ] = useState('');
	const [ status, setStatus ] = useState(null);

    useEffect(() => {
		const { search } = location;
		const params = queryString.parse(search);
		setName(params.name);
		setStatus(params.status);
	}, [location.search])

    const onSearch = () => {
		const params = { name: name, status: status }
		history.replace({...location, search: queryString.stringify(params)});
	}

	const onClear = () => {
		setName('');
		setStatus(null);
		history.replace({...location, search: ''});
	}

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="portlet light bordered">
                    <div className="portlet-title">
                        <div className="caption">
                            <i className=" icon-layers font-red" />
                            <span className="caption-subject font-red sbold uppercase">Service Project</span>
                        </div>
                    </div>
                    <div className="portlet-body form">
                        <form autoComplete="off" className="form-horizontal">
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-md-line-input">
                                            <label className="col-md-2 control-label">Name</label>
                                            <div className="col-md-8">
                                                <input type="text" name="name" className="form-control" value={name || ''} onChange={e => setName(e.target.value)}/>
                                                <div className="form-control-focus"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-md-line-input">
                                            <label className="control-label col-md-2">Status</label>
                                            <div className="col-md-8">
                                                <div className="md-radio-inline">
                                                    <div className="md-radio">
                                                        <input type="radio" id="checkbox1_6" name="status" value="active" className="md-radiobtn"
                                                            checked={status === 'active'}
                                                            onChange={() => setStatus('active')} />
                                                        <label htmlFor="checkbox1_6">
                                                            <span />
                                                            <span className="check" />
                                                            <span className="box" /> Active
                                                        </label>
                                                    </div>
                                                    <div className="md-radio">
                                                        <input type="radio" id="checkbox1_7" name="status" value="inactive" className="md-radiobtn"
                                                            checked={status === 'inactive'}
                                                            onChange={() => setStatus('inactive')} />
                                                        <label htmlFor="checkbox1_7">
                                                            <span />
                                                            <span className="check" />
                                                            <span className="box" /> InActive
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-actions">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <Link to={ RoutesService.getPath('ADMIN', 'SERVICE_PROJECT_FORM', { method: 'create' }) } className="btn green">
                                            <i className="fa fa-plus" /> Create
                                        </Link>
                                        <button type="button" className="btn blue" onClick={onSearch}><i className="fa fa-search" /> Search</button>
                                        <button type="button" className="btn default" onClick={onClear}><i className="fa fa-refresh" /> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListFilter;