import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import { RoutesService } from 'my-routes';

const pStyle = {
    whiteSpace: 'pre-line'
}

const Footer = () => {
    const [ languageCode ] = useState(localStorage.getItem('_l'));
    const [ t ] = useTranslation();
    const { item } = useSelector(state => ({ item: state.option.items }), shallowEqual);

    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="logo text-center">
                    <img src="/portal/img/logo.png" alt=""/>
                </div>
                <div className="slogan text-center">
                    <h2><strong>MORE VISION, MORE SUCCESS</strong></h2>
                    <p>{item.maxim}</p>
                </div>
                <div className="footer-info row no-margin">
                    <div className="text-center">
                        <div className="align-middle clearfix">
                            <div className="align-middle col-mobile-12">
                                <div className="text-left pull-left footer-info-detail col-mobile-6 col-mobile-fullwidth">
                                    <img className="icon-2" src="/portal/img/phone.png" alt=""/>
                                    <div className="align-top">
                                        <h5 className="text-color text-uppercase"><strong>{t('call_us')}</strong></h5>
                                        <p style={pStyle}>{item.phone}</p>
                                    </div>
                                </div>
                                <div className="text-left pull-left footer-info-detail col-mobile-6 col-mobile-fullwidth">
                                    <img className="icon-2" src="/portal/img/email-2.png" alt=""/>
                                    <div className="align-top">
                                        <h5 className="text-color text-uppercase"><strong>{t('email')}</strong></h5>
                                        <p style={pStyle}>{item.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="align-middle col-mobile-12">
                                <div className="text-left pull-left footer-info-detail col-mobile-6 col-mobile-fullwidth">
                                    <img className="col-mobile-4 icon-2 align-top" src="/portal/img/location.png" alt=""/>
                                    <div className="col-mobile-8 align-top">
                                        <h5 className="text-color text-uppercase"><strong>{t('office_location_hcm')}</strong></h5>
                                        <p style={pStyle}>{item[`address_${languageCode}`]}</p>
                                        <br></br>
                                        <h5 className="text-color text-uppercase"><strong>{t('office_location_hn')}</strong></h5>
                                        <p style={pStyle}>{item[`address_hn_${languageCode}`]}</p>
                                    </div>
                                </div>
                                
                                <div className="text-left pull-left footer-info-detail col-mobile-6 col-mobile-fullwidth">
                                    <img className="icon-2" src="/portal/img/timely.png" alt=""/>
                                    <div className="align-top">
                                        <h5 className="text-color text-uppercase"><strong>{t('working_hour')}</strong></h5>
                                        <p style={pStyle}>{item[`working_hour_${languageCode}`]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-contact text-center">
                    <Link to={{ pathname: RoutesService.getPath('PORTAL', 'CONTACT'), hash: 'form' }} className="btn no-border-radius btn-main text-uppercase">
                        {t('contact_us')}
                    </Link>
                    { item.facebook && <a className="icon icon-brand" href={item.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a> }
                    { !item.facebook && <a className="icon icon-brand" href="#"><i className="fab fa-facebook-f"></i></a> }
                </div>
                <div className="footer-copyright">
                    <h6>© <Moment format="YYYY"/> Vision Vietnam. All rights reserved.</h6>
                </div>
            </div>
        </div>
    );
}

export default Footer;