import React, { useEffect, useState } from 'react';

import { AppConfig } from 'my-constants';
import { FormContent } from 'my-components/admin/options';
import { OptionsService } from 'my-services/repositories';
import { Notification } from 'my-utils';

const Form = () => {
	const [ model, setModel ] = useState({});
	const { LANGUAGES } = AppConfig;

	useEffect(() => {
		let options = new URLSearchParams();
		options.set('status', 'active');
		OptionsService.getAll(options).then(res => {
			if(res.success){
				let data = {};
				for(let i in res.data){
					let rec = res.data[i];
					data[rec.key] = rec.value;
				}
				setModel(data);
			}
		});
	}, []);

	const onSave = values => {
		let formData = new FormData();
		formData.append('options[maxim]', values['maxim']);
		formData.append('options[email]', values['email']);
		formData.append('options[phone]', values['phone']);
		formData.append('options[facebook]', values['facebook']);
		formData.append('options[address]', values['address']);
		formData.append('options[working_hour]', values['working_hour']);

		for(let i in LANGUAGES){
			let item = LANGUAGES[i];
			formData.append(`options[address_${item.code}]`, values[`address_${item.code}`]);
			formData.append(`options[working_hour_${item.code}]`, values[`working_hour_${item.code}`]);
		};

		OptionsService.save(formData).then(res => {
			if(res.success){
				Notification.success('Saved!');
			}
		});
	}

	return (
		<div className="portlet light bordered">
			<div className="portlet-title">
				<div className="caption">
					<i className=" icon-layers font-red" />
					<span className="caption-subject font-red sbold uppercase">Options</span>
				</div>
			</div>
			<div className="portlet-body form">
				<FormContent initialValues={model} name="form1" onSubmit={onSave} form={'FormView1'} />
			</div>
		</div>
	);
}

export default Form;