import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

import { AppConfig } from 'my-constants';
import { Confirm } from 'my-utils/components/ui/modal';
import UIDatatables from 'my-utils/components/ui/table/UIDatatables';
import { HeadlineService } from 'my-services/repositories';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';

const ImgTag = props => {
	const { src, alt } = props;
	return (<img src={src} alt={alt} height="80"/>)
}

const ListData = props => {
	const [ deleteID, setDeleteID ] = useState(null);
	const [ openModal, setOpenModal ] = useState(false);
	const [ reload, setReload ] = useState(false);
	const { LANGUAGES } = AppConfig;
	const { category } = props;
	const queryParams = { category: category, status: 'active' };
	let headline = category ? category.replace('-', ' ') : '';

	const handleRowsData = data => {
		const newData = data.map(item => {
			item.html_description = (<HTMLEllipsis unsafeHTML={item.description} maxLine={2} basedOn="words" />);
			item.image = (<ImgTag src={item.image_url} alt={item.filename} />);

			for(let i in LANGUAGES){
				let ob = LANGUAGES[i];
				if(ob.code === item.language_code) item.language_code = ob.name;
			}

			item.action = (
				<>
					<Link to={{
						pathname: RoutesService.getPath('ADMIN', 'HEADLINE_FORM', { method : 'update', id : item.id }),
						state: { category: category }
					}}>
						<i className="fa fa-edit" />
					</Link>
					&nbsp;&nbsp;
					<a href="#" onClick={event => onOpenConfirm(event, item.id)} >
						<i className="fa fa-trash" />
					</a>
				</>
			);
			return item;
		});
		return newData;
	}

	const onOpenConfirm = (e, id) => {
		e.preventDefault();
		setDeleteID(id);
		setOpenModal(true);
	}

	const onCloseConfirm = () => {
		setOpenModal(false);
		setReload(true);
	}

	const onDelete = () => {
		HeadlineService.delete(deleteID).then(res => {
			if(res.success){
				Notification.success('Deleted!');
				setOpenModal(false);
				setReload(true);
			}
		});
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 text-right">
					<div className="portlet light bordered">
						<div className="portlet-title">
							<div className="caption">
								<i className=" icon-layers font-red" />
								<span className="caption-subject font-red sbold uppercase">{headline} Title</span>
							</div>
							<Link to={{
									pathname: RoutesService.getPath('ADMIN', 'HEADLINE_FORM', { method : 'create' }),
									state: { category: category }
							}} className="btn green">
								<i className="fa fa-plus" /> Create
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<UIDatatables
						columns={[
							{ name: 'title', title: 'Title' },
							{ name: 'html_description', title: 'Description' },
							{ name: 'image', title: 'Image' },
							{ name: 'language_code', title: 'Language' },
							{ name: 'action', title: 'Action' },
						]}
						handleRowsData={handleRowsData}
						url={HeadlineService.serviceUrl}
						queryParams={queryParams}
						sorting={[{ columnName: 'OrderNumber', direction: 'asc' }]}
						// // disbaled sort column
						sortingStateColumnExtensions={[
							{ columnName: 'Action', sortingEnabled: false },
						]}
						showSelection={false}
						reload={reload}
						reloaded={() => setReload(false)}
					/>
				</div>
				<Confirm show={openModal} onHide={onCloseConfirm} onExec={onDelete} />
			</div>
		</>
	);
}

export default ListData;
