import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = props => {
    const location = useLocation();

    useLayoutEffect(() => {
        setTimeout(() => { window.scrollTo(0, 0); }, 200);
    }, [location.pathname]);

    return props.children;
}

export default ScrollToTop;