import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { fetchOptionAll } from 'my-actions/option';
import { fetchRecruitmentAll } from 'my-actions/recruitment';
import { fetchStaffAll } from 'my-actions/staff';
import { Loading } from 'my-components/admin/layouts';
import { Header, Footer } from 'my-containers/portal/partials';
import { ScrollToTop } from 'my-containers/portal/utils';
import { OptionsService, RecruitmentService, StaffService } from 'my-services/repositories';
import { Helpers } from 'my-utils';
import { RenderRoutes } from 'my-routes/portal';

const MainLayout = () => {
    const dispatch = useDispatch();
    const [ languageCode ] = useState(localStorage.getItem('_l'));
    const [ optionsSource ] = useState(OptionsService.getCancelTokenSource());
    const [ recruitmentSource ] = useState(RecruitmentService.getCancelTokenSource());
    const [ staffSource ] = useState(StaffService.getCancelTokenSource());

    const getOptions = () => {
        let params = new URLSearchParams();
        params.set('status', 'active');
        dispatch(fetchOptionAll(params, optionsSource.token));
    }

    const getRecruitment = () => {
		let params = new URLSearchParams();
		params.set('language_code', languageCode);
		params.set('status', 'active');
		params.set('sort[ordering]', 'desc');
		dispatch(fetchRecruitmentAll(params, recruitmentSource.token));
    }

    const getStaff = () => {
        let params = new URLSearchParams();
		params.set('language_code', languageCode);
        params.set('status', 'active');
        dispatch(fetchStaffAll(params, staffSource.token));
    }

    useEffect(() => {
        // dispatch all common services API
        getOptions();
        getRecruitment();
        getStaff();

        return () => {
            optionsSource.cancel('Unmount');
            recruitmentSource.cancel('Unmount');
            staffSource.cancel('Unmount');
        }
    }, []);

    return (
        <Router>
            <ScrollToTop>
                <div className="wrapper">
                    <Suspense fallback={<Loading />}>
                        <Header/>
                        <RenderRoutes />
                        <Footer/>
                    </Suspense>
                    <button className="btn-scrollTop" onClick={() => Helpers.scrollTo}><i className="fas fa-chevron-up text-color-white"></i></button>
                </div>
                <Loading/>
            </ScrollToTop>
        </Router>
    )
}

export default MainLayout;