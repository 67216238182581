import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const customStyles = {
	content : {
		top					: '50%',
		left				: '50%',
		right				: '0%',
		bottom				: '0%',
		marginRight			: '-50%',
		padding				: '0px',
		transform			: 'translate(-50%, -50%)',
		maxHeight			: '1000px',
		maxWidth			: '750px',
		overlfow			: 'scroll'
	},
	overlay: {
		backgroundColor		: 'rgba(0, 0, 0, 0.8)',
		zIndex				: 10
	}
};

const Apply = props => {
	const { el, item, show, onApply, onHide } = props;
	const [ t ] = useTranslation();

	useEffect(() => {
		show && (document.body.style.overflow = 'hidden');
		!show && (document.body.style.overflow = '');
	}, [show]);

	const createMarker = content => {
		return { __html: content };
	}

	return (
		<Modal
			isOpen={show}
			parentSelector={el}
			ariaHideApp={false}
			onRequestClose={onHide}
			style={customStyles}
			contentLabel="Apply Modal"
			className="modal-content no-border-radius"
		>
			<div className="modal-header career-header">
				<button type="button" className="close" onClick={onHide}>
					<i className="fas fa-times"></i>
				</button>
				<div className="career-title">{item.name}</div>
			</div>
			<div className="modal-body">
				<div className="career-intro" dangerouslySetInnerHTML={createMarker(item.content)} />
				<div className="career-detail clearfix">
					<div className="career-description clearfix">
						<div className="pull-left col-1 col-mobile-1 icon">
							<img className="icon-3" src="/portal/img/icon-job-description.png" alt="" />
						</div>
						<div className="pull-right col-11 col-mobile-11">
							<h4><strong>Job description</strong></h4>
							<div dangerouslySetInnerHTML={createMarker(item.description)} />
						</div>
					</div>
					<div className="career-request clearfix">
						<div className="pull-left col-1 col-mobile-1 icon">
							<img className="icon-3" src="/portal/img/icon-job-description.png" alt="" />
						</div>
						<div className="pull-right col-11 col-mobile-11">
							<h4><strong>Request</strong></h4>
							<div dangerouslySetInnerHTML={createMarker(item.requirement)} />
						</div>
					</div>
				</div>
				<div className="text-center">
					<button className="btn btn-main no-border-radius text-uppercase" onClick={onApply}>{t('apply_now')}</button>
				</div>
			</div>
		</Modal>
	)
}

export default Apply;