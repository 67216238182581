import React from 'react';

import { ListFilter, ListData } from 'my-components/admin/service-project';

const List = () => {
	return (
		<section>
			<ListFilter />
			<ListData />
		</section>
	)
}

export default List;