import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';

import { AppConfig } from 'my-constants';
import { renderTextField } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';

const Form = props => {
    const { handleSubmit, submitting } = props;
    const { LANGUAGES } = AppConfig;

    return (
        <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="form-body">
                <div className="form-group">
                    <label className="control-label col-md-2">Maxim <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="maxim" multiline={true} rows="2" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Email <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="email" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div>
                {
                    LANGUAGES.map((item, idx) => {
                        return (
                            <Fragment key={idx}>
                                <div className="form-group">
                                    <label className="control-label col-md-2">Address ({item.code})<span className="required">*</span></label>
                                    <div className="col-md-10">
                                        <Field name={`address_${item.code}`} multiline={true} rows="2" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-md-2">Working Hour ({item.code})<span className="required">*</span></label>
                                    <div className="col-md-10">
                                        <Field name={`working_hour_${item.code}`} multiline={true} rows="2" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })
                }
                {/* <div className="form-group">
                    <label className="control-label col-md-2">Address <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="address" multiline={true} rows="2" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Working Hour <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="working_hour" multiline={true} rows="2" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div> */}
                <div className="form-group">
                    <label className="control-label col-md-2">Phone <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="phone" multiline={true} rows="2" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Facebook</label>
                    <div className="col-md-10">
                        <Field name="facebook" component={renderTextField} fullWidth={true}/>
                    </div>
                </div>
            </div>
            <div className="form-actions">
                <div className="row">
                    <div className="col-md-offset-5 col-md-7">
                        <button className="btn green" type="submit" disabled={submitting}><i className="fa fa-save" /> Save</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

const FormContent = reduxForm({enableReinitialize: true})(Form);

export default FormContent;