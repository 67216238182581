import { FETCH_STAFF_BEGIN, FETCH_STAFF_SUCCESS, FETCH_STAFF_FAILURE } from 'my-actions/staff';

const initialState = {
    pending: false,
    items: [],
    error: null
}

const staff = (state = initialState, action) => {
    switch(action.type){
        case FETCH_STAFF_BEGIN:
            return {
                ...state,
                pending: true
            }
        case FETCH_STAFF_SUCCESS:
            return {
                ...state,
                pending: false,
                items: action.payload
            }
        case FETCH_STAFF_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
			return state;
	}
}

export default staff;