import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/Button';

import { renderTextField, renderRadioGroup } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { RoutesService } from 'my-routes';

const Form = props => {
	const { handleSubmit, submitting, reset } = props

	return (
		<form className="form-horizontal" onSubmit={handleSubmit}>
			<div className="form-body">
				<div className="form-group">
					<label className="control-label col-md-2">Name <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="name" component={renderTextField} fullWidth={true} validate={[rule.required]} />
					</div>
				</div>

				<div className="form-group">
					<label className="control-label col-md-2">Status</label>
					<div className="col-md-10">
						<Field name="status" component={renderRadioGroup} labelPosition="right" className="fredux-radio-inline" validate={[rule.required]} >
							<FormControlLabel value="active" className="col-md-2" control={<Radio color="primary" />} label="Active" />
							<FormControlLabel value="inactive" className="col-md-2" control={<Radio color="primary" />} label="InActive" />
						</Field>
					</div>
				</div>
			</div>
			<div className="form-actions">
				<div className="row">
					<div className="col-md-offset-5 col-md-7">
						<button className="btn green" type="submit" disabled={submitting}><i className="fa fa-save" /> Save</button>
						<button className="btn blue" type="button" disabled={submitting} onClick={reset}><i className="fa fa-refresh" /> Clear</button>
						<Link to={ RoutesService.getPath('ADMIN', 'BLOG_CATEGORY_LIST') } className="btn default">
							<i className="fa fa-arrow-left" /> Back
						</Link>
					</div>
				</div>
			</div>
		</form>
	);
}

const FormContent = reduxForm({enableReinitialize: true})(Form);

export default FormContent;