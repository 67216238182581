import React, { Component } from 'react';

import { MainLayout } from './layouts';

class AppView extends Component{
    render() {
        return ( <MainLayout/> );
    };
}

export default AppView;