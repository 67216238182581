import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Header, Breadcrumb, MainNavigation, Footer, Loading } from 'my-components/admin/layouts';
import { RenderRoutes } from 'my-routes/admin';

const theme = createMuiTheme({
    overrides: {
		MuiInput : {
			underline : {
				'&:after' : {borderBottom: '2px solid #36c6d3'},
						'&:hover:not($disabled):not($error):not($focused):before' : {borderBottom: '2px solid #36c6d3'},
			},
		},
    },
    palette: {
      	primary: {main : '#36c6d3', contrastText : 'white'},
	},
	typography: {
		useNextVariants: true
	}
});

const MainLayout = () => {
	return (
		<MuiThemeProvider theme={theme}>
			<section>
				<div className="page-header-fixed page-sidebar-closed-hide-logo page-content-white">
					<div className="page-wrapper">
						<Header />
						<div className="page-container">
							<MainNavigation />
							<div className="page-content-wrapper">
								<div className="page-content">
									<Breadcrumb />
									<RenderRoutes />
								</div>
							</div>
						</div>
						<Footer />
					</div>
				</div>
				<Loading />
			</section>
		</MuiThemeProvider>
	)
}

export default MainLayout;
