import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    loader_ui: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: 1000
    },
    loader_ui_spinner: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)'
    },
    progress: {
        margin: theme.spacing.unit * 2
    }
});

const Loading = (props) =>  {
    const { classes } = props;
    return (
        <div className={classes.loader_ui}>
            <div className={classes.loader_ui_spinner}>
                <CircularProgress className={classes.progress}/>
            </div>
        </div>
    );
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loading);
