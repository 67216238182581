import axios from 'axios';
import { isEmpty } from 'lodash';

import { AppConfig } from 'my-constants';
import { Helpers } from 'my-utils';
import { AuthService } from 'my-services/system';

class HttpService {
    constructor() {
        let config = {
            withCredentials: false, //reject authen token
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }
        //Inital Service
        this.service = axios.create(config);
        this.service.interceptors.request.use(this.handleRequest, this.handleError);
        this.service.interceptors.response.use(this.handleSuccess, this.handleError);
    }

    getCancelTokenSource = () => {
        return axios.CancelToken.source();
    }

    getCancelToken = () => {
        let source = this.getCancelTokenSource();
        return source.token;
    }

    //Create request with method get
    get = (path, queryParams, cancelToken) => {
        if(isEmpty(cancelToken)){
            cancelToken = this.getCancelToken();
        }

        return this.service.get(path, {
            params: queryParams,
            cancelToken: cancelToken
        });
    }

    //Create request with method post
    post = (path, payload, cancelToken) => {
        if(isEmpty(cancelToken)){
            cancelToken = this.getCancelToken();
        }
        return this.service.post(path, payload, { cancelToken: cancelToken });
    }

    //Create request with method delete
    delete = (path, queryParams, cancelToken) => {
        if(isEmpty(cancelToken)){
            cancelToken = this.getCancelToken();
        }

        return this.service.delete(path, {
            params: queryParams,
            cancelToken: cancelToken
        });
    }

    //Handle berfore send request
    handleRequest = config => {
        // Display spinner
        Helpers.showLoading();
        // set authorization header
        const { API_AUTH_USER, API_AUTH_PASS } = AppConfig;
        config.headers.Authorization = 'Basic ' + btoa(`${API_AUTH_USER}:${API_AUTH_PASS}`);
        // set JWT token
        const token = AuthService.getToken();
        config.headers['X-Auth-Token'] = `Bearer ${token}`;
        return config;
    }

    //Handle when request sucessful
    handleSuccess = response => {
        Helpers.hideLoading();
        //obj response : data, status, statusText, headers
        const { data } = response;
        return data;
    }

    //Handle when request fail
    handleError = error => {
        Helpers.hideLoading();
        const response = error.response;
        console.log(`${response.status} - ${response.statusText}: ${response.data}`);
        return Promise.reject(response);
    }
}

export default new HttpService();