import { ActionType } from 'my-constants';

export default (state = [], action) => {
    switch(action.type){
        case ActionType.INIT_DATA:
        case ActionType.SEARCH_DATA:
            return action;
        default:
			return state;
	}
}