
import { ActionType } from 'my-constants';

const authenticated = (state = [], action) => {
	switch(action.type){
		case ActionType.AUTH_ADD_DATA:
			return action;
		default:
			return state;
	}
}

export default authenticated;