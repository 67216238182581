import { AppConfig } from 'my-constants';
import { BaseService } from 'my-utils/core';

class UploadService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/system/upload/`;

    tinyMCE(blobInfo) {
        return new Promise((resolve, reject) => {
            var xhr, formData;

            xhr = new XMLHttpRequest();
            // xhr.withCredentials = true;
            xhr.open('POST', this.serviceUrl);
            xhr.setRequestHeader('Authorization', 'Basic ' + btoa(`${AppConfig.API_AUTH_USER}:${AppConfig.API_AUTH_PASS}`));

            xhr.onload = function() {
                let res;

                res = JSON.parse(xhr.responseText);

                if (!res.success) {
                    reject(res);
                    return;
                }
                resolve(res);
            };

            formData = new FormData();
            formData.append('file[]', blobInfo.blob(), blobInfo.filename());

            xhr.send(formData);
        });
    }
}

export default new UploadService()
