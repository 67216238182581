import { AppConfig } from 'my-constants';
import { HttpService } from 'my-utils/core';
import { Algorithm } from 'my-utils';

class AuthService {
    serviceUrl = `${AppConfig.API_URL}`;

    session_expired = `${AppConfig.SESSION_EXPIRED}`;

    encodePrefixLength = 5;
    encodeSuffixLength = 2;

    //Local storage variable name
    var_user_info = '_i';
    var_session_start = '_t';
    var_system_token = '_k';

    /*
    |--------------------------------------------------------------------------
    | Handle Paramerter & Get Authenticate From API
    |--------------------------------------------------------------------------
    */
    login(params) {
        return HttpService.post(`${this.serviceUrl}/login`, params).then(res => {
            if (res.code === 200) {
                this.setUserInfo(res.data.payload);
                this.setToken(res.data.token);
                this.setSessionStart(new Date().getTime());
            }

            return res;
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Handle Logout
    |--------------------------------------------------------------------------
    */
    logout(redirect_url = '/login', callback_uri = null) {
        return HttpService.post(`${this.serviceUrl}/logout`).then( res => {
            if (res.code === 200) {
                localStorage.clear();

                if (callback_uri) {
                    // this._Router.navigate([redirect_url], { queryParams: { callback_uri: callback_uri } });
                } else {
                    // this._Router.navigate([redirect_url]);
                }
            }
            return res;
        });
    }

    /*
    |--------------------------------------------------------------------------
    | Check Session Data Is Valid
    |--------------------------------------------------------------------------
    */
    checkSessionValid(callback_uri = null) {
        if(this.isSessionValid()) {
            //Valid
            console.log('Valid');
        } else {
            //Invalid
            console.log('Invalid');
        }
    }

    isSessionValid() {
        let now = new Date().getTime();
        let session_start = this.getSessionStart();
        let user_info = this.getUserInfo();
        let token = this.getToken();
        if (now - +session_start > this.session_expired*60*60*1000 || !user_info || !token) {
            //session invalid
            return false;
        }
        //session invalid
        return true;
    }

    /*
    |--------------------------------------------------------------------------
    | Process Store Information Of User
    |--------------------------------------------------------------------------
    */
    setUserInfo(obj) {
        if(obj) localStorage.setItem(this.var_user_info, this.encodeData(obj));
    }

    /*
    |--------------------------------------------------------------------------
    | Get All Information Of User
    |--------------------------------------------------------------------------
    */
    getUserInfo() {
        let user_info = localStorage.getItem(this.var_user_info);
        return this.decodeData(user_info);
    }

    /*
    |--------------------------------------------------------------------------
    | Process Store Session Start
    |--------------------------------------------------------------------------
    */
    setSessionStart(timestamp) {
        if(timestamp) localStorage.setItem(this.var_session_start, timestamp);
    }

    /*
    |--------------------------------------------------------------------------
    | Get All Information Of User
    |--------------------------------------------------------------------------
    */
    getSessionStart() {
        return localStorage.getItem(this.var_session_start);
    }

    /*
    |--------------------------------------------------------------------------
    | Process Store Information Of User
    |--------------------------------------------------------------------------
    */
    setToken(token) {
        if(token) localStorage.setItem(this.var_system_token, token);
    }

    /*
    |--------------------------------------------------------------------------
    | Get All Information Of User
    |--------------------------------------------------------------------------
    */
    getToken() {
        return localStorage.getItem(this.var_system_token);
    }

    /*
    |--------------------------------------------------------------------------
    | Encode Data
    |--------------------------------------------------------------------------
    */
    encodeData(obj) {
        let prefix = Algorithm.randomString(this.encodePrefixLength);
        let suffix = Algorithm.randomString(this.encodeSuffixLength);
        let strData = btoa(JSON.stringify(obj));

        //Reverse String Data;
        strData.split('').reverse().join('');

        strData = prefix + strData + suffix;
        return strData;
    }

    /*
    |--------------------------------------------------------------------------
    | Decode Data
    |--------------------------------------------------------------------------
    */
    decodeData(str) {
        let obj = str;
        if (typeof str === "string"){
            str = str.substr(this.encodePrefixLength);
            str = str.slice(0, -this.encodeSuffixLength);
            //Reverse String Data;
            str.split('').reverse().join('');
            try {
                obj = JSON.parse(atob(str));
            } catch(err) {
                this.logout('/login');
            }
        }
        return obj;
    }
}

export default new AuthService()
