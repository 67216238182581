import { HeadlineService } from 'my-services/repositories';

export const FETCH_HEADLINE_BEGIN = 'FETCH_HEADLINE_BEGIN';
export const FETCH_HEADLINE_SUCCESS = 'FETCH_HEADLINE_SUCCESS';
export const FETCH_HEADLINE_FAILURE = 'FETCH_HEADLINE_FAILURE';

export function fetchHeadline(params, token) {
    return dispatch => {
        dispatch(fetchBegin());
        return HeadlineService.getAll(params, token).then(
            res => {
                if(res.success){
                    let items = {};
                    if(res.data.length) items = res.data.shift();
                    dispatch(fetchSuccess(items));
                }
                return res.data;
            },
            error => { dispatch(fetchFailure(error)) }
        )
    }
}

export function fetchHeadlineAll(params, token) {
    return dispatch => {
        dispatch(fetchBegin());
        return HeadlineService.getAll(params, token).then(
            res => {
                dispatch(fetchSuccess(res.data));
                return res.data;
            },
            error => { dispatch(fetchFailure(error)) }
        )
    }
}

const fetchBegin = () => ({ type: FETCH_HEADLINE_BEGIN });
const fetchSuccess = items => ({ type: FETCH_HEADLINE_SUCCESS, payload: items });
const fetchFailure = error => ({ type: FETCH_HEADLINE_FAILURE, error: error });