import { lazy } from 'react';

const AboutUs = lazy(() => import('my-views/portal/about-us/DetailView'));
const BlogDetail = lazy(() => import('my-views/portal/blog/DetailView'));
const BlogList = lazy(() => import('my-views/portal/blog/ListView'));
const Career = lazy(() => import('my-views/portal/career/DetailView'));
const Contact = lazy(() => import('my-views/portal/contact/DetailView'));
const Dashboard = lazy(() => import('my-views/portal/home/DashboardView'));
const ServiceDetail = lazy(() => import('my-views/portal/service/DetailView'));
const ServiceList = lazy(() => import('my-views/portal/service/ListView'));
const PageNotFound = lazy(() => import('my-views/portal/page-not-found/DetailView'));

const Routes = [
	{
		path: "/",
		exact: true,
		component: Dashboard,
	},
	{
		path: "/about-us",
		exact: true,
		component: AboutUs,
	},
	{
		path: "/blog",
		exact: true,
		component: BlogList,
	},
	{
		path: "/blog/detail/:clean_url?",
		exact: true,
		component: BlogDetail,
	},
	{
		path: "/career",
		exact: true,
		component: Career,
	},
	{
		path: "/contact",
		exact: true,
		component: Contact,
	},
	{
		path: "/service",
		exact: true,
		component: ServiceList,
	},
	{
		path: "/service/detail/:clean_url?",
		exact: true,
		component: ServiceDetail,
	},
	{
		path: '',
		exact: true,
		component: PageNotFound
	}
];

export default Routes;