import { FETCH_HEADLINE_BEGIN, FETCH_HEADLINE_SUCCESS, FETCH_HEADLINE_FAILURE } from 'my-actions/headline';

const initialState = {
    pending: false,
    items: [],
    error: null
}

const headline = (state = initialState, action) => {
    switch(action.type){
        case FETCH_HEADLINE_BEGIN:
            return {
                ...state,
                pending: true
            }
        case FETCH_HEADLINE_SUCCESS:
                return {
                    ...state,
                    pending: false,
                    items: action.payload
                }
        case FETCH_HEADLINE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
			return state;
	}
}

export default headline;