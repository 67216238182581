import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

import { AppConfig } from 'my-constants';
import { RoutesService } from 'my-routes';

const NavbarExtend = () => {
	const { LANGUAGES } = AppConfig;
	const { i18n } = useTranslation();
	const item = useSelector(state => state.option.items, shallowEqual);
	const language_code = localStorage.getItem('_l');
	let current_lang = find(LANGUAGES, function(o){ return o.code === language_code });

	const onChangeLanguage = (event, language_code) => {
		event.preventDefault();
		i18n.changeLanguage(language_code);
		localStorage.setItem('_l', language_code);
		window.location.reload();
	}

	return (
		<ul className="nav navbar-nav menu-extend">
			<li className="icon-mail">
				<Link to={{
					pathname: RoutesService.getPath('PORTAL', 'CONTACT'),
					hash: 'form'
				}}>
					<img src="/portal/img/email.png" alt={item.email} />
				</Link>
			</li>
			<li className="header-divider"></li>
			<li className="dropdown btn-lang">
				<a className="dropdown-toggle navbar-item" data-toggle="dropdown" href="#">
					<span className="lang-flag"><img src={current_lang.src} alt={current_lang.code} /></span>&nbsp;
					<span className="lang-name">{current_lang.name}</span>
					<span className="caret"></span>
				</a>
				<ul className="dropdown-menu lang-option">
					{
						LANGUAGES.map((item, idx) => {
							return (
								<li key={idx}>
									<a href="#" onClick={event => onChangeLanguage(event, item.code)}>
										<span className="lang-flag"><img src={item.src} alt={item.code} /></span>&nbsp;
										<span className="lang-name">{item.name}</span>
									</a>
								</li>
							)
						})
					}
				</ul>
			</li>
		</ul>
	)
}

export default NavbarExtend;