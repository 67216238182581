import React, { createContext, useEffect, useState } from 'react';

import { AppConfig } from 'my-constants';
import { AuthService } from 'my-services/system';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [ user, setUser ] = useState(null);
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const [ token, setToken ] = useState(null);
    //Local storage variable name
    const var_user_info = '_i';
    const var_session_start = '_t';
    const var_system_token = '_k';

    useEffect(() => {
        let now = new Date().getTime();
        let obj = localStorage.getItem(var_user_info);
        let current_user = AuthService.decodeData(obj);
        let session_start = localStorage.getItem(var_session_start);
        let system_token = localStorage.getItem(var_system_token);
        let ttl = AppConfig.SESSION_EXPIRED * 1000 * 60 * 60; // time to live
        let valid = (ttl > (now - +session_start) || !!current_user || !!system_token);

        setIsLoggedIn(valid);
        if(valid) {
            login({ payload: current_user, token: system_token }, session_start, false);
        } else {
            try {
                AuthService.logout();
            } catch(error) {}
            logout();
        }
    }, []);

    useEffect(() => {
        // recheck session after every 1 minute
        let interval = setInterval(_ => {
            if(token){
                let now = new Date().getTime();
                let session_start = localStorage.getItem(var_session_start);
                let ttl = AppConfig.SESSION_EXPIRED * 1000 * 60 * 60; // time to live
                if((now - +session_start) > ttl){
                    try {
                        AuthService.logout();
                    } catch(error) {}
                    logout();
                }
            }
        }, 60000);

        return () => clearInterval(interval);
    }, [token]);

    const login = (credentials, timestamp = null, save = true) => {
        setToken(credentials.token);
        setUser(credentials.payload);
        setIsLoggedIn(true);
        /* Process Store Information Of User */
        if(save){
            localStorage.setItem(var_user_info, AuthService.encodeData(credentials.payload));
            localStorage.setItem(var_system_token, credentials.token);
            localStorage.setItem(var_session_start, timestamp || new Date().getTime());
        }
    }

    const logout = () => {
        setIsLoggedIn(false);
        setToken(null);
        setUser(null);
        localStorage.clear();
    }

    return (
        <AuthContext.Provider value={{user, isLoggedIn, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;