import React from 'react';

import { AppConfig } from 'my-constants';

const Navtab = props => {
	const { LANGUAGES } = AppConfig;
	const { language_code } = props;

	const handleClickTab = e => {
		let language_code = e.currentTarget.dataset.lang;
		props.onClickTab(language_code);
	}

	return (
		<ul className="nav nav-tabs">
			{
				LANGUAGES.map((item, idx) => {
					let expanded = language_code === item.code;
					let active = expanded ? 'active' : '';
					return (
						<li className={active} key={idx}>
							<a href={"#tab_" + item.code} className="link-tab" data-toggle="tab" data-lang={item.code} aria-expanded={expanded} onClick={handleClickTab}> {item.name} </a>
						</li>
					)
				})
			}
		</ul>
	)
}

export default Navtab;