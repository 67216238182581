import { FETCH_RECRUITMENT_BEGIN, FETCH_RECRUITMENT_SUCCESS, FETCH_RECRUITMENT_FAILURE } from 'my-actions/recruitment';

const initialState = {
    pending: false,
    items: [],
    error: null
}

const recruitment = (state = initialState, action) => {
    switch(action.type){
        case FETCH_RECRUITMENT_BEGIN:
            return {
                ...state,
                pending: true
            }
        case FETCH_RECRUITMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                items: action.payload
            }
        case FETCH_RECRUITMENT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
			return state;
	}
}

export default recruitment;