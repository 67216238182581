import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        maxHeight             : '1000px',
		maxWidth              : '500px',
    },
	overlay: {
		backgroundColor		: 'rgba(0, 0, 0, 0.8)',
		zIndex				: 10
	}
};

const Confirm = props => {
    const { show, onExec, onHide } = props;

    return (
        <Modal
            isOpen={show}
            ariaHideApp={false}
            onRequestClose={onHide}
            style={customStyles}
            contentLabel="Apply Modal"
            className="modal-content"
        >
            <div className="modal-header">
                <h5 className="modal-title fs_24 ro_b">Xác nhận</h5>
            </div>
            <div className="modal-body">
                <p>Xóa nội dung vừa nhập</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn_blue btn_cancel" onClick={onHide}>HỦY BỎ</button>
                <button type="button" className="btn btn_blue btn_send" onClick={onExec}>XÓA</button>
            </div>
        </Modal>
    )
}

export default Confirm;