import React from 'react';
import { Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RoutesService } from 'my-routes';

const links = [
	{ to: RoutesService.getPath('PORTAL', 'DASH_BOARD'), exact: true, name: 'home', image_url: '/portal/img/icon-home-active.png' },
	{ to: RoutesService.getPath('PORTAL', 'ABOUT_US'), exact: true, name: 'about_us', image_url: '/portal/img/icon-about-us.png' },
	{ to: RoutesService.getPath('PORTAL', 'SERVICE'), exact: true, name: 'service', image_url: '/portal/img/icon-services.png' },
	// { to: RoutesService.getPath('PORTAL', 'BLOG'), exact: true, name: 'blog', image_url: '/portal/img/icon-blog.png' },
	{ to: RoutesService.getPath('PORTAL', 'CAREER'), exact: true, name: 'career', image_url: '/portal/img/icon-briefcase.png' },
];

const Menu = () => {
	const { t } = useTranslation();
	let html = '';

	html = links.map((link, idx) => {
		return (
			<Route
				key={idx}
				path={link.to}
				exact={link.exact}
				children={
					({ match }) => {
						let active = (match) ? 'active' : '';
						return (
							<li className={`${active} icon-menu`}>
								<Link to={link.to} className="navbar-item">
									<img src={link.image_url} alt={link.name} /><span className="text-uppercase">{t(link.name)}</span>
								</Link>
							</li>
						)
					}
				}
			/>
		)
	});

	return html;
}

const NavbarMenu = () => {
	return (
		<ul className="nav navbar-nav menu">
			<Menu />
		</ul>
	);
}

export default NavbarMenu;