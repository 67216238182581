import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import queryString from 'query-string';

import { AppConfig } from 'my-constants';
import { Confirm } from 'my-utils/components/ui/modal';
import UIDatatables from 'my-utils/components/ui/table/UIDatatables';
import { ServiceService } from 'my-services/repositories';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';

const ImgTag = props => {
	const { src, alt } = props;
	return (<img src={src} alt={alt} height="80"/>)
}

const ListData = () => {
	const location = useLocation();
	const [ deleteID, setDeleteID ] = useState(null);
	const [ openModal, setOpenModal ] = useState(false);
	const [ queryParams, setQueryParams ] = useState({});
	const [ reload, setReload ] = useState(false);
	const { LANGUAGES } = AppConfig;

	useEffect(() => {
		const { search } = location;
		const params = queryString.parse(search);
		setQueryParams(params);
	}, [ location.search ])

	const handleRowsData = data => {
		const newData = data.map(item => {
			item.html_description = (<HTMLEllipsis unsafeHTML={item.description} maxLine={2} basedOn="words" />);
			item.image = (<ImgTag src={item.image_url} alt={item.filename} />);

			for(let i in LANGUAGES){
				let ob = LANGUAGES[i];
				if(ob.code === item.language_code) item.language_code = ob.name;
			}

			item.action = (
				<>
					<Link to={ RoutesService.getPath('ADMIN', 'SERVICE_FORM', { method : 'update', id : item.id }) }>
						<i className="fa fa-edit" />
					</Link>
					&nbsp;&nbsp;
					<a href="#" onClick={event => onOpenConfirm(event, item.id)} >
						<i className="fa fa-trash" />
					</a>
				</>
			);

			return item;
		});
		return newData;
	}

	const onOpenConfirm = (e, id) => {
		e.preventDefault();
		setDeleteID(id);
		setOpenModal(true);
	}

	const onCloseConfirm = () => {
		setOpenModal(false);
		setReload(true);
	}

	const onDelete = () => {
		ServiceService.delete(deleteID).then(res => {
			if(res.success){
				Notification.success('Deleted!');
				setOpenModal(false);
				setReload(true);
			}
		});
	}

	return (
		<div className="row">
			<div className="col-md-12">
				<UIDatatables
					columns={[
						{ name: 'ordering', title: 'Ordering' },
						{ name: 'name', title: 'Name' },
						{ name: 'html_description', title: 'Description' },
						{ name: 'image', title: 'Image' },
						{ name: 'language_code', title: 'Language' },
						{ name: 'status', title: 'Status' },
						{ name: 'action', title: 'Action' },
					]}
					handleRowsData={handleRowsData}
					url={ServiceService.serviceUrl}
					queryParams={queryParams}
					sorting={[{ columnName: 'OrderNumber', direction: 'asc' }]}
					// // disbaled sort column
					sortingStateColumnExtensions={[
						{ columnName: 'Action', sortingEnabled: false },
					]}
					columnExtensions={[
						{ columnName: 'ordering', width: 80, align: 'center' },
						{ columnName: 'name', width: 200 },
						{ columnName: 'description', width: 280 },
						{ columnName: 'image', width: 150 },
						{ columnName: 'language_code', width: 100, align: 'center' },
						{ columnName: 'status', width: 100, align: 'center' },
						{ columnName: 'action', width: 100, align: 'center' },
					]}
					showSelection={false}
					reload={reload}
					reloaded={() => setReload(false)}
				/>
			</div>
			<Confirm show={openModal} onHide={onCloseConfirm} onExec={onDelete} />
		</div>
	);
}

export default ListData;
