import { OptionsService } from 'my-services/repositories';

export const FETCH_OPTION_BEGIN = 'FETCH_OPTION_BEGIN';
export const FETCH_OPTION_SUCCESS = 'FETCH_OPTION_SUCCESS';
export const FETCH_OPTION_FAILURE = 'FETCH_OPTION_FAILURE';

export function fetchOptionAll(params, token) {
    return dispatch => {
        dispatch(fetchBegin());
        return OptionsService.getAll(params, token).then(
            res => {
                let data = {};
                for(let i in res.data){
                    let item = res.data[i];
                    data[item.key] = item.value;
                }
                dispatch(fetchSuccess(data));
                return data;
            },
            error => { dispatch(fetchFailure(error)) }
        )
    }
}

const fetchBegin = () => ({ type: FETCH_OPTION_BEGIN });
const fetchSuccess = items => ({ type: FETCH_OPTION_SUCCESS, payload: items });
const fetchFailure = error => ({ type: FETCH_OPTION_FAILURE, error: error });