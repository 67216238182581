import React from 'react';

import { ListData } from 'my-components/admin/partner';

const List = () => {
	return (
		<section>
			<ListData />
		</section>
	)
}

export default List;