import React from 'react';
import { Link } from 'react-router-dom';
import { Field , reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';

import { renderTextField } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { BaseComponent } from 'my-utils/core';

class ForgotPasswordView extends BaseComponent {

    onSubmit(formProps){
        console.log(formProps);
    }

    render() {
        const { handleSubmit , submitting} = this.props;
        return (
            <div className=" login" style={{ backgroundColor : 'transparent' }}>
                <div className="logo">
                    {/* <a href="">
                        <img src="" alt="" />
                    </a> */}
                </div>
                <div className="content">
                    <form className="form-horizontal " onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <h3 className="form-title font-green">Forgot password</h3>
                        <div className="form-group">
                            <Field name="username" component={renderTextField} label="Username" fullWidth={true} validate={[rule.required, rule.email]} />
                        </div>
                        <div className="form-actions" style={{borderBottom : 'none'}}>
                            <Button variant="outlined" component={Link} to="/auth/login" style={{border : '1px solid #36c6d3', color : '#36c6d3'}}>Back</Button>
                            <Button variant="raised" color="primary"  type="submit" disabled={submitting} style={{float :'right'}}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: 'ForgotPasswordView',
    enableReinitialize: true,
})(ForgotPasswordView);

