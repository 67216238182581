import React, { useEffect, useState } from 'react';

import { ListFilter, ListData } from 'my-components/admin/blog';
import { BlogCategoryService } from 'my-services/repositories';

const List = () => {
	const [ items, setItems ] = useState([]);
	const [ source ] = useState(BlogCategoryService.getCancelTokenSource());

	useEffect(() => {
		let params = new URLSearchParams();
		params.set('status', 'active');
		BlogCategoryService.getAll(params, source.token).then(
			res => {
				if(res.success){
					setItems(res.data);
				}
			},
			error => { console.log(error) }
		);

		return () => source.cancel('Unmount');
	}, []);

	return (
		<section>
			<ListFilter items={items}/>
			<ListData/>
		</section>
	)
}

export default List;