import React from 'react';

import { ListFilter, ListData } from 'my-components/admin/recruitment';

const List = () => {
	return (
		<section>
			<ListFilter/>
			<ListData/>
		</section>
	)
}

export default List;