import React from 'react';
import { useHistory } from 'react-router-dom';

import { FormContent } from 'my-components/admin/partner';
import { PartnerService } from 'my-services/repositories';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';

const Form = () => {
	const history = useHistory();

	const onSave = values =>{
		let formData = new FormData();
		let uploader = values['file_attach'];

		for(let key in uploader){
			let upload = uploader[key];
			// Khoa Nguyen - 2017-03-13 - fix issue when attach file on firefox
			let objUpload = new Blob([upload]);
			formData.append('file_attach[]', objUpload, upload.name);
		}

		PartnerService.save(formData).then(res => {
			if(res.success){
				Notification.success('Saved!');
				history.push(RoutesService.getPath('ADMIN', 'PARTNER_LIST'));
			}
		});
	}

	return (
		<div className="portlet light bordered">
			<div className="portlet-title">
				<div className="caption">
					<i className=" icon-layers font-red" />
					<span className="caption-subject font-red sbold uppercase">Partner</span>
				</div>
			</div>
			<div className="portlet-body form">
				<FormContent name="form1" onSubmit={onSave} form={'FormView1'} />
			</div>
		</div>
	)
}

export default Form;