import React from 'react';
import { TextField, Checkbox, RadioGroup, FormHelperText, InputLabel, Select, FormControl, FormControlLabel } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { concat } from 'lodash';
import moment from 'moment';
import TinyMCE from 'my-utils/components/editor/TinyMCE';

import 'react-day-picker/lib/style.css';

const styles = {
	noLabel: {
		marginTop: '0'
	},
	selectNoLabel: {
		marginTop: 0,
		marginBottom: 8
	}
};

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

export const renderTextField = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => {
	let properties = {
		type: custom.type,
		error: (touched && error? true: false),
		value: input.value,
		onChange: input.onChange,
		margin: "normal",
		fullWidth: true,
		label: label,
		multiline: custom.multiline,
		rows: custom.rows,
		InputProps: custom.readOnly ? { readOnly: custom.readOnly } : {}
	}

	return (
		<div>
			<TextField
				{...properties}
				style={!label ? styles.noLabel : {}}
			/>
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</div>
	)
};

export const renderSelectField = ({
	input,
	label,
	meta: { touched, error },
	children,
	...custom
}) => {
	return (
		<FormControl style={{display: 'block'}}>
			{label && <InputLabel error={(touched && error? true: false)}>{label}</InputLabel>}
			<Select
				error={(touched && error? true: false)}
				value={input.value}
				onChange={event => input.onChange(event.target.value)}
				children={children}
				{...custom}
				style={!label ? styles.selectNoLabel : {marginBottom: 8}}
				inputProps={{
					name: input.name,
					id: input.name
				}}
				autoWidth={custom.fullWidth}
			/>
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	)
};

export const renderCheckbox = ({ input, label }) => (
	<Checkbox
		label={label}
		checked={input.value ? true : false}
		onCheck={input.onChange}
	/>
);

export const renderRadioGroup = ({
	input,
	children,
	meta: { touched, error },
	...rest
}) => {
	return (
		<div>
			<RadioGroup
				{...input}
				// {...rest}
				// children={children}
				value={input.value}
				onChange={(event, value) => input.onChange(value)}
				style={rest.style}
				className={rest.className}
			>
				{
					children.map(function(item, key) {
						return (<FormControlLabel key={key} {...item.props} />)
					})

				}
			</RadioGroup>
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</div>
	);
};

// tinymce editor
export const renderEditor = ({
	input,
	meta: { touched, error },
	children,
	...custom
}) => (
	<div>
		<TinyMCE
			{...input}
			content={input.value}
			// initialValues={input.value}
			onChange={(e) =>  input.onChange(e)}
			children={children}
			{...custom}
		/>
		{
			touched &&
			((error && <span className="error" >{error}</span>))
		}
	</div>
);

export const renderDropzone = ({
	input,
	label,
	meta: { touched, error },
	onAccepted,
	children,
	...custom
}) => {
	// const onDrop = useCallback(acceptedFiles => {
	//     input.onChange(acceptedFiles)
	// }, []);
	// const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject  } = useDropzone({onDrop});

	let limit = custom.filesLimit || 1;
	let properties = {
		accept: custom.accept,
		multiple: custom.multiple || false,
		name: input.name,
		maxSize: custom.maxSize, //byte
	}

	const files = input.value;

	return (
		<>
		<div style={baseStyle}>
			<Dropzone
				onDropAccepted={(acceptedFiles, rejectedFiles, e) => {
					let new_file = acceptedFiles;
					if(Array.isArray(files)){
						new_file = concat(files, new_file);
					}
					input.onChange(new_file);
					onAccepted && onAccepted(acceptedFiles, true, limit);
				}}
				onDropRejected={(files, e) => {
					onAccepted(files, false, limit);
				}}
				{...properties} >
				{({getRootProps, getInputProps}) => (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<p>Drag and drop files here, or click to select files</p>
					</div>
				)}
			</Dropzone>
		</div>
		{
			touched &&
			((error && <FormHelperText error>{error}</FormHelperText>))
		}
		</>
	)
};

export const renderDatePicker = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => {
	const customProps = custom.minDate ? {disabledDays: { before: custom.minDate }} : {};

	return (
		<div>
			<DayPickerInput
				// component={props => <TextField {...props} />}
				component={TextField}
				dayPickerProps={customProps}
				format={custom.formatDate}
				formatDate={formatDate}
				parseDate={parseDate}
				placeholder={custom.placeholder || ''}
				onDayChange={day => input.onChange(moment(day).format(custom.formatDate))}
				value={input.value ? moment(input.value).format(custom.formatDate) : undefined}
			/>
			{
				touched &&
				((error && <span className="error" >{error}</span>))
			}
		</div>
	)
}