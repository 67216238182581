import React from 'react';
import { useHistory } from 'react-router-dom';

const PageNotFound = () => {
	const history = useHistory();

	return (
		<div className="main is-404">
			<div className="row">
				<div className="col-md-12 col-lg-10 col-lg-offset-1">
					<div className="control-404">
						<div className="img-404">
							<img src="/portal/img/404.png" alt="404" />
						</div>
						<div className="content-404">
							<p className="head-404">PAGE NOT FOUND</p>
							<a className="btn-goback" href="#" onClick={history.goBack}><img src="/portal/img/left_chevron.png" alt="Go Back" /><p>GO BACK</p></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PageNotFound;