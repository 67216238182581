import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';


const notifycationConfig = {
    timeOut: 5000, // by setting to 0 it will prevent the auto close
    newestOnTop: false,
    preventDuplicates: true,
    position: "bottom-right",
    transitionIn: "bounceIn",
    transitionOut: "bounceOut",
    progressBar: true
}


class Notification {

    success(title, message) {
        toastr.success(title, message, notifycationConfig);
    }

    error(title, message) {
        toastr.error(title, message, notifycationConfig);
    }

    warning(title, message) {
        toastr.warning(title, message, notifycationConfig);
    }

    info(title, message) {
        toastr.info(title, message, notifycationConfig);
    }
}

export default new Notification();