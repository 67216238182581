import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { Confirm } from 'my-utils/components/ui/modal';
import UIDatatables from 'my-utils/components/ui/table/UIDatatables';
import { SlideService } from 'my-services/repositories';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';

const ImgTag = props => {
	const { src, alt } = props;
	return (<img src={src} alt={alt} height="80"/>)
}

const ListData = () => {
	const location = useLocation();
	const [ deleteID, setDeleteID ] = useState(null);
	const [ openModal, setOpenModal ] = useState(false);
	const [ queryParams, setQueryParams ] = useState({});
	const [ reload, setReload ] = useState(false);

	useEffect(() => {
		const { search } = location;
		const params = queryString.parse(search);
		setQueryParams(params);
	}, [ location.search ])


	const handleRowsData = data => {
		const newData = data.map(item => {
			item.image = (<ImgTag src={item.image_url} alt={item.filename} />)

			item.action = (
				<a href="#" onClick={event => onOpenConfirm(event, item.id)} >
					<i className="fa fa-trash" />
				</a>
			);
			return item;
		});
		return newData;
	}

	const onOpenConfirm = (e, id) => {
		e.preventDefault();
		setDeleteID(id);
		setOpenModal(true);
	}

	const onCloseConfirm = () => {
		setOpenModal(false);
		setReload(true);
	}

	const onDelete = () => {
		SlideService.delete(deleteID).then(res => {
			if(res.success){
				Notification.success('Deleted!');
				setOpenModal(false);
				setReload(true);
			}
		});
	}

	return (
		<section>
			<div className="row">
				<div className="col-md-12 text-right">
					<div className="portlet light bordered">
						<div className="portlet-title">
							<div className="caption">
								<i className=" icon-layers font-red" />
								<span className="caption-subject font-red sbold uppercase">Background</span>
							</div>
							<Link to={ RoutesService.getPath('ADMIN', 'BACKGROUND_FORM', { method : 'create' }) } className="btn green">
								<i className="fa fa-plus" /> Create
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<UIDatatables
						columns={[
							{ name: 'image', title: 'Image' },
							{ name: 'status', title: 'Status' },
							{ name: 'action', title: 'Action' },
						]}
						handleRowsData={handleRowsData}
						url={SlideService.serviceUrl}
						queryParams={queryParams}
						sorting={[{ columnName: 'OrderNumber', direction: 'asc' }]}
						// // disbaled sort column
						sortingStateColumnExtensions={[
							{ columnName: 'Action', sortingEnabled: false },
						]}
						columnExtensions={[
							{ columnName: 'Image', width: 20 },
							{ columnName: 'Status', width: 10, align: 'center' },
							{ columnName: 'Action', width: 10, align: 'center' },
						]}
						showSelection={false}
						reload={reload}
						reloaded={() => setReload(false)}
					/>
				</div>
				<Confirm show={openModal} onHide={onCloseConfirm} onExec={onDelete}/>
			</div>
		</section>
	)
}

export default ListData;
