import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

// test
import common from './common';
import headline from './headline';
import option from './option';
import recruitment from './recruitment';
import service from './service';
import staff from './staff';

//real
import authenticated from './authenticated';

const appReducers = combineReducers({
    //redux form
    form: formReducer,
    //test
    common,
    headline,
    option,
    recruitment,
    service,
    staff,
    // real
    authenticated,
    toastr: toastrReducer
});

export default appReducers;