import { find, forEach } from "lodash";

// import { AppConfig } from "my-constants";

const listAdminRoutes = [
    /*=================================== Dashboard ===================================*/
    { name: "DASH_BOARD", path: "/admin", language: "en"},
    { name: "DASH_BOARD", path: "/admin", language: "vi"},
    /*=================================== Auth ===================================*/
    { name: "AUTH_LOGIN", path: "/admin/auth/login", language: "en"},
    { name: "AUTH_LOGIN", path: "/admin/auth/login", language: "vi"},
    /*==========================================================================*/
    { name: "NEWS_LIST", path: "/admin/news/list", language: "en"},
    { name: "NEWS_LIST", path: "/admin/tin-tuc/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "BACKGROUND_LIST", path: "/admin/background/list", language: "en"},
    { name: "BACKGROUND_LIST", path: "/admin/background/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "BACKGROUND_FORM", path: "/admin/background/form/:method/:id?", language: "en"},
    { name: "BACKGROUND_FORM", path: "/admin/background/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "BLOG_LIST", path: "/admin/blog/list", language: "en"},
    { name: "BLOG_LIST", path: "/admin/blog/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "BLOG_FORM", path: "/admin/blog/form/:method/:id?", language: "en"},
    { name: "BLOG_FORM", path: "/admin/blog/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "BLOG_CATEGORY_LIST", path: "/admin/blog-category/list", language: "en"},
    { name: "BLOG_CATEGORY_LIST", path: "/admin/blog-danh-muc/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "BLOG_CATEGORY_FORM", path: "/admin/blog-category/form/:method/:id?", language: "en"},
    { name: "BLOG_CATEGORY_FORM", path: "/admin/blog-danh-muc/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "HEADLINE_FORM", path: "/admin/headline/form/:method/:id?", language: "en"},
    { name: "HEADLINE_FORM", path: "/admin/tieu-de/form/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "OPTIONS_FORM", path: "/admin/options/form", language: "en"},
    { name: "OPTIONS_FORM", path: "/admin/tuy-chon/form", language: "vi"},
    /*==========================================================================*/
    { name: "PARTNER_LIST", path: "/admin/partner/list", language: "en"},
    { name: "PARTNER_LIST", path: "/admin/doi-tac/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "PARTNER_FORM", path: "/admin/partner/form/:method/:id?", language: "en"},
    { name: "PARTNER_FORM", path: "/admin/doi-tac/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "RECRUITMENT_LIST", path: "/admin/recruitment/list", language: "en"},
    { name: "RECRUITMENT_LIST", path: "/admin/tuyen-dung/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "RECRUITMENT_FORM", path: "/admin/recruitment/form/:method/:id?", language: "en"},
    { name: "RECRUITMENT_FORM", path: "/admin/tuyen-dung/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "SERVICE_LIST", path: "/admin/service/list", language: "en"},
    { name: "SERVICE_LIST", path: "/admin/dich-vu/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "SERVICE_FORM", path: "/admin/service/form/:method/:id?", language: "en"},
    { name: "SERVICE_FORM", path: "/admin/dich-vu/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "SERVICE_PROJECT_LIST", path: "/admin/service-project/list", language: "en"},
    { name: "SERVICE_PROJECT_LIST", path: "/admin/du-an/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "SERVICE_PROJECT_FORM", path: "/admin/service-project/form/:method/:id?", language: "en"},
    { name: "SERVICE_PROJECT_FORM", path: "/admin/du-an/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
    { name: "STAFF_LIST", path: "/admin/staff/list", language: "en"},
    { name: "STAFF_LIST", path: "/admin/nhan-vien/danh-sach", language: "vi"},
    /*==========================================================================*/
    { name: "STAFF_FORM", path: "/admin/staff/form/:method/:id?", language: "en"},
    { name: "STAFF_FORM", path: "/admin/nhan-vien/form-vi/:method/:id?", language: "vi"},
    /*==========================================================================*/
];

const listPortalRoutes = [
    /*=================================== Dashboard ===================================*/
    { name: "DASH_BOARD", path: "/", language: "en"},
    { name: "DASH_BOARD", path: "/", language: "vi"},
    /*==========================================================================*/
    { name: "ABOUT_US", path: "/about-us", language: "en"},
    { name: "ABOUT_US", path: "/about-us", language: "vi"},
    /*==========================================================================*/
    { name: "BLOG", path: "/blog", language: "en"},
    { name: "BLOG", path: "/bai-viet", language: "vi"},
    /*==========================================================================*/
    { name: "BLOG_DETAIL", path: "/blog/detail/:clean_url?", language: "en"},
    { name: "BLOG_DETAIL", path: "/bai-viet/noi-dung/:clean_url?", language: "vi"},
    /*==========================================================================*/
    { name: "CONTACT", path: "/contact", language: "en"},
    { name: "CONTACT", path: "/lien-he", language: "vi"},
    /*==========================================================================*/
    { name: "PARTNER", path: "/partner", language: "en"},
    { name: "PARTNER", path: "/doi-tac", language: "vi"},
    /*==========================================================================*/
    { name: "SERVICE", path: "/service", language: "en"},
    { name: "SERVICE", path: "/dich-vu", language: "vi"},
    /*==========================================================================*/
    { name: "SERVICE_DETAIL", path: "/service/detail/:clean_url?", language: "en"},
    { name: "SERVICE_DETAIL", path: "/dich-vu/noi-dung/:clean_url?", language: "vi"},
    /*==========================================================================*/
    { name: "CAREER", path: "/career", language: "en"},
    { name: "CAREER", path: "/tuyen-dung", language: "vi"},
];

/*
|--------------------------------------------------------------------------
| Class Manage Routes
| Ex:
|   - RoutesService.setLanguage('en').getPath('ADMIN', 'BLOG_FORM', { method: 'create'}, {name: 'acb', age: 32});
|   - RoutesService.getPath('ADMIN', 'BLOG_FORM', { method: 'create'}, {name: 'acb', age: 32});
|--------------------------------------------------------------------------
*/
class RoutesService {
    // language = AppConfig.DEFAULT_LANG;
    language = "en";

    /*
    |--------------------------------------------------------------------------
    | Set Language
    |--------------------------------------------------------------------------
    */
    setLanguage(language) {
        this.language = language;
        return this;
    }

    /*
    |--------------------------------------------------------------------------
    | @params: { method: heroId, id: 'foo' }
    | @queryParams: {name: "teo", age: 34}
    | @hash: '#string'
    | Ex: ('ADMIN', 'BLOG_FORM', { method: 'create'}, {name: 'acb', age: 32})
    |--------------------------------------------------------------------------
    */
    getPath(area, name, params, queryParams, hash) {
        let routes;
        switch (area) {
            case "ADMIN":
                routes = find(listAdminRoutes, { name, language : this.language });
                break;
            default:
                routes = find(listPortalRoutes, { name, language : this.language});
                break;
        }

        if(typeof routes !== "undefined") {
            return this.buildFullPath(routes.path, params, queryParams, hash);
        } else {
            return "/";
        }
    }

    /*
    |--------------------------------------------------------------------------
    | Build url with params & query params
    |--------------------------------------------------------------------------
    */
    buildFullPath(path, params, queryParams, hash) {
        // Add Params Into Path
        if(params) {
            forEach(params, (value, key) => {
                let pattern = new RegExp(`/:${key}[^/]?[^?]?`, "gi");
                path = path.replace(pattern, `/${value}/`);
            });
            //Remove All Params Without Specified
            path = path.replace(/:.*[^/]?[^?]?/gi, '');
            //Remove Last / In Path
            path = path.replace(/\/$/gi, '');
        }

        // Add Query Params Into Path
        if(queryParams) {
            if(typeof queryParams === 'string'){
                path = `${path}/${queryParams}`;
            }else{
                const queryString = this.serialize(queryParams);
                //Check path exist query params
                if(path.indexOf('?') !== -1) {
                    path = `${path}&${queryString}`;
                } else {
                    path = `${path}?${queryString}`;
                }
            }
        }

        // Add Hash Into Path
        if(hash){
            path = `${path}#${hash}`;
        }

        return path;
    }

    /*
    |--------------------------------------------------------------------------
    | Convert Object To Query String
    |--------------------------------------------------------------------------
    */
    serialize(object) {
        let str = [];
        for (let p in object)
            if (object.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
            }
        return str.join("&");
    }
}

export default new RoutesService();