import React from 'react';
import { cloneDeep } from 'lodash';

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
};

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box',
	position: 'relative'
};

const thumbInner = {
	display: 'flex',
	overflow: 'hidden',
	justifyContent: "center",
	alignItems: "center"
};

const img = {
	display: 'block',
	width: '85%',
	height: '85%'
};

const btnClose = {
	position: "absolute",
	right: -10,
	top: -10,
	borderRadius: 25,
	borderWidth: 1,
	borderColor: "#ddd",
	backgroundColor: "#fff",
	borderStyle: "solid",
	paddingLeft: 5,
	paddingRight: 5,
	paddingTop: 2,
	paddingBottom: 2
};

const Preview = props => {
	const { files, setInputFile } = props;

	const onDelete = idx => {
		let queue = cloneDeep(files);
		queue.splice(idx, 1);
		setInputFile(queue);
	}

	return (
		files.length > 0 &&
		<aside style={thumbsContainer}>
			{
				files.map((file, idx) => {
					return (
						<div style={thumb} key={idx}>
							<div style={thumbInner}>
								<img src="/admin/global/img/file.png" style={img} />
							</div>
							<div style={btnClose}>
								<button type="button" className="close" onClick={() => onDelete(idx)}><i className="fas fa-times"></i></button>
							</div>
							{/* <p>{file.name}</p> */}
						</div>
					);
				})
			}
		</aside>
	)
}

export default Preview;