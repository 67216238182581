import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { defer } from 'rxjs';

import { AppConfig } from 'my-constants';
import { FormContent } from 'my-components/admin/service-project';
import { Navtab } from 'my-components/admin/partials';
import { AuthService } from 'my-services/system';
import { ServiceService, ServiceProjectService } from 'my-services/repositories';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';
import { init as initAction } from 'my-actions/common';

const Form = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id, method } = useParams();
	const [ categories, setCategories ] = useState([]);
	const [ languageCode, setLanguageCode ] = useState('vi');
	const [ model, setModel ] = useState({ status: 'active' });
	const { LANGUAGES } = AppConfig;

	useEffect(() => {
		let options = new URLSearchParams();
		options.set('status', 'active');
		ServiceService.getAll(options).then(res => {
			if(res.success){
				setCategories(res.data);
			}
		});

		dispatch(initAction(model));
		if(method === 'update'){
			ServiceProjectService.getByID(id).then(res => {
				if(res.success){
					setLanguageCode(res.data.language_code);
					setModel(res.data);
					dispatch(initAction(res.data));
				}
			});
		}
	}, [method, id]);

	const onSave = values =>{
		const current_user_info = AuthService.getUserInfo();

		let formData = new FormData();
		let uploader = values['file_attach'];

		for(let key in uploader){
			let upload = uploader[key];
			//Khoa Nguyen - 2017-03-13 - fix issue when attach file on firefox
			let objUpload = new Blob([upload]);
			formData.append('file_attach', objUpload, upload.name);
		}

		for(let key in values){
			if(key === 'file_attach') continue;
			formData.append(key, values[key]);
		}
		formData.append('language_code', languageCode);
		formData.append('user_id', current_user_info['id']);

		const project$ = defer(() => (method === 'create') ? ServiceProjectService.save(formData) : ServiceProjectService.update(id, formData));
		project$.subscribe(res => {
			if(res.success){
				Notification.success('Saved!');
				history.push(RoutesService.getPath('ADMIN', 'SERVICE_PROJECT_LIST'));
			}
		});
	}

	return (
		<div className="portlet light bordered">
			<div className="portlet-title">
				<div className="caption">
					<i className=" icon-layers font-red" />
					<span className="caption-subject font-red sbold uppercase">Service Project</span>
				</div>
			</div>
			<div className="portlet-body form">
				<div className="tabable-line boxless margin-bottom-20">
					<Navtab language_code={languageCode} onClickTab={code => setLanguageCode(code)} />
				</div>
				<div className="tab-content">
					{
						LANGUAGES.map((item, idx) => {
							let no = idx+1;
							return (
								<div className={"tab-pane fade " + (languageCode === item.code ? 'active in' : '')} id={"tab_" + item.code} key={idx}>
									<FormContent key={item.code} initialValues={model} name={`form${no}`} method={method} onSubmit={onSave} form={`FormView${no}`} categories={categories} />
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default Form;