import React, { useLayoutEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Field, reduxForm } from 'redux-form';
import { cloneDeep, findIndex } from 'lodash';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/Button';

import { AppConfig } from 'my-constants';
import { renderTextField, renderRadioGroup, renderDropzone } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { Preview } from 'my-components/admin/partials';
import { Notification } from 'my-utils';
import { RoutesService } from 'my-routes';

const Form = props => {
    const [ iconUploader, setIconUploader ] = useState([]);
    const [ imageUploader, setImageUploader ] = useState([]);
	const { MAX_FILESIZE, UPLOAD_FILE_EXTENSION } = AppConfig;
	const { change, handleSubmit, method, reset, submitting, untouch } = props;
	const validate = (method === 'update' && imageUploader.length) ? [] : [rule.required];
    const ext = UPLOAD_FILE_EXTENSION.join();

    const item = useSelector(state => state.common.data, shallowEqual);

    useLayoutEffect(() => {
        if(item?.filename && item?.filepath){
            let queue = [];
            const file = {
                name: item.filename,
                path: item.filepath,
            }
            queue.push(file);
            setImageUploader(queue);
        }

        if(item?.icon_path){
            let queue = [];
            const file = {
                name: 'Icon Service',
                path: item.icon_path,
            }
            queue.push(file);
            setIconUploader(queue);
        }

        return () => {
            setImageUploader([]);
            setIconUploader([]);
        }
    }, [item]);

    const handleImage = (files, accepted, limit) => handleFile(files, accepted, limit, 'image');

    const handleIcon = (files, accepted, limit) => handleFile(files, accepted, limit, 'icon');

    const handleFile = (files, accepted, limit, type) => {
        let uploader = cloneDeep((type === 'image') ? imageUploader : iconUploader);

        if(!accepted){
            for(let k in files){
                let file = files[k];
                // invalid format
                if(UPLOAD_FILE_EXTENSION.indexOf(file.type) === -1){
                    Notification.error(file.name + "'s format is invalid");
                }
                // invalid filesize
                if(file.size > MAX_FILESIZE){
                    Notification.error(file.name + "'s size is invalid");
                }
            }
        }else{
            if(limit > uploader.length){
                for(let k in files){
                    uploader.push(files[k]);
                }
            }else{
                let remain = limit - uploader.length;
                if(remain){
                    for(let k in files){
                        let file = files[k];
                        if(findIndex(uploader, file) === -1){
                            if(remain){
                                uploader.push(files[k]);
                                remain--;
                            }
                        }
                    }
                }else{
                    Notification.error(`You can only upload maximum ${limit} files.`);
                }
            }

            (type === 'image') ? setImageUploader(uploader) : setIconUploader(uploader);
        }
    }

    const setInputFileImage = files => {
        setImageUploader(files);
        change('file_attach', '');
        untouch('file_attach');
    }

    const setInputFileIcon = files => {
        setIconUploader(files);
        change('icon_path', '');
        untouch('icon_path');
    }

    const onReset = () => {
        reset();
        // reset image
        if(item.id && item.filename && item.filepath){
            let queue = [];
            const file = {
                name: item.filename,
                path: item.filepath,
            }
            queue.push(file);
            setImageUploader(queue);
        }
        // reset icon
        if(item.id && item.icon_path){
            let queue = [];
            const file = {
                name: 'Icon Service',
                path: item.icon_path,
            }
            queue.push(file);
            setIconUploader(queue);
        }
    }

    return (
        <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="form-body">
                <div className="form-group">
                    <label className="control-label col-md-2">Name <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="name" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Description <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="description" multiline={true} rows="3" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Image <span className="required">*</span></label>
                    <div className="col-md-10">
                        <Field name="file_attach" component={renderDropzone} multiple={false} accept={ext} maxSize={MAX_FILESIZE} onAccepted={handleImage} validate={validate} />
                        <Preview key="image" files={imageUploader} setInputFile={files => setInputFileImage(files)} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Icon</label>
                    <div className="col-md-10">
                        <Field name="icon_attach" component={renderDropzone} multiple={false} accept={ext} maxSize={MAX_FILESIZE} onAccepted={handleIcon} />
                        <Preview key="icon" files={iconUploader} setInputFile={files => setInputFileIcon(files)} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">URL Title</label>
                    <div className="col-md-10">
                        <Field name="url_title" component={renderTextField} fullWidth={true} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">URL Link</label>
                    <div className="col-md-10">
                        <Field name="url_link" component={renderTextField} fullWidth={true} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Ordering</label>
                    <div className="col-md-10">
                        <Field name="ordering" component={renderTextField} fullWidth={true} validate={[rule.number]} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-2">Status</label>
                    <div className="col-md-10">
                        <Field name="status" component={renderRadioGroup} labelPosition="right" className="fredux-radio-inline">
                            <FormControlLabel value="active" className="col-md-2" control={<Radio color="primary" />} label="Active" />
                            <FormControlLabel value="inactive" className="col-md-2" control={<Radio color="primary" />} label="Inactive" />
                        </Field>
                    </div>
                </div>
            </div>
            <div className="form-actions">
                <div className="row">
                    <div className="col-md-offset-5 col-md-7">
                        <button className="btn green" type="submit" disabled={submitting}><i className="fa fa-save" /> Save</button>
                        <button className="btn blue" type="button" disabled={submitting} onClick={onReset}><i className="fa fa-refresh" /> Clear</button>
                        <Link to={ RoutesService.getPath('ADMIN', 'SERVICE_LIST') } className="btn default">
                            <i className="fa fa-arrow-left" /> Back
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
}

const FormContent = reduxForm({form: 'serviceForm', enableReinitialize: true})(Form);

export default FormContent;