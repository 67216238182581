class Algorithm {
    /*
    |--------------------------------------------------------------------------
    | Make Random String
    | @input: length of string
    | @return: new string
    |--------------------------------------------------------------------------
    */
    randomString(length = 5) {
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789|+=";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
   }
}

export default new Algorithm();