import React, { Component } from 'react';
import { connect } from 'react-redux';

import UIDatatables from 'my-utils/components/ui/table/UIDatatables';
import { actFetchMockData } from 'my-actions/mock';

class ListData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    handleRowData = data => {
        data.map(item => {
            item.action = (<a href="#tab_vi" className="link-tab" data-toggle="tab" data-lang="vi" aria-expanded="true"> Tiếng Việt </a>)
            return item;
        });
    }

    render() {
        console.log(this.state.data);
        return (
            <div className="row">
                <div className="col-md-12">
                    <UIDatatables
                        columns={[
                            { name: 'OrderNumber', title: 'Category Name' },
                            { name: 'OrderDate', title: 'Order Date' },
                            { name: 'action', title: 'Action' },
                        ]}
                        // handleRowsData={data => this.handleRowData(data)}
                        handleRowsData={this.handleRowsData}
                        url="https://js.devexpress.com/Demos/WidgetsGallery/data/orderItems"
                        sorting={[{ columnName: 'StoreCity', direction: 'asc' }]}
                        // // disbaled sort column
                        sortingStateColumnExtensions={[
                            { columnName: 'action', sortingEnabled: false },
                        ]}
                        showSelection={true}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        mock: state.mock
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMockData: () => {
            dispatch(actFetchMockData());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListData);
