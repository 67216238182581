import React from 'react';

import 'my-styles/loading.css';

const Loading = () => {
    return (
        <div className="wrap-loader">
            <div style={{ color: '#36c6d3' }} className="la-ball-scale-ripple-multiple la-3x">
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}

export default Loading;