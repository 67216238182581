import React from 'react';

import { List as ListHeadline } from 'my-containers/admin/headline';
import { List } from 'my-containers/admin/recruitment';

const ListView = () => {
	return (
		<>
			<ListHeadline category="recruitment" />
			<List/>
		</>
	);
}

export default ListView;