import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Radio, MenuItem, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/Button';

import { renderTextField, renderRadioGroup, renderSelectField, renderEditor } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';

const data = {title: "Tin tức mới nhất", gender: "male", feature_slide_flag: "2", news_category_id: "1", order: "23", memo: "memo", memo2: 'memo2', color: 'Red'};
const colors = ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet'];

class FormContent extends Component {
    handleClick() {
        this.props.loadData(null);
        setTimeout(() => {
            this.props.loadData(data);
        }, 10);
    }

    render() {
        console.log(this.props, 'handleSubmithandleSubmit');
        let { title, handleSubmit, submitting } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit} >
                <div className="form-body">
                    <div>
                        <Button variant="raised"  type="submit" color="primary" disabled={submitting} onClick={() => this.handleClick()} >
                            Load Data
                        </Button>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">{title} <span className="required">*</span></label>
                        <div className="col-md-10">
                            <Field name="title" component={renderTextField} fullWidth={true} validate={[rule.required]} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Category <span className="required">*</span></label>
                        <div className="col-md-10">
                            <Field name="news_category_id" component={renderSelectField} fullWidth={true} validate={[rule.required]} >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="1">Disabled</MenuItem>
                                <MenuItem value="2">Every Night</MenuItem>
                            </Field>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Color <span className="required">*</span></label>
                        <div className="col-md-10">
                            <Field name="color" component={renderSelectField} fullWidth={true} validate={[rule.required]} >
                                {
                                    colors.map((item, k) => (
                                        <MenuItem key={k} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Field>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Order</label>
                        <div className="col-md-10">
                            <Field name="order" min={0} type="number" component={renderTextField} fullWidth={true} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Feature</label>
                        <div className="col-md-10">
                            <Field name="feature_slide_flag" component={renderRadioGroup} labelPosition="right" className="fredux-radio-inline" validate={[rule.required]} >
                                <FormControlLabel value="male" className="col-md-2" control={<Radio color="primary" />} label="Female" />
                                <FormControlLabel value="female" className="col-md-2" control={<Radio color="primary" />} label="Female" />
                                <FormControlLabel value="diff" className="col-md-2" control={<Radio color="primary" />} label="Difference" />
                            </Field>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Feature Slide</label>
                        <div className="col-md-10">
                            <div className="md-radio-inline">
                                <Field name="gender" component={renderRadioGroup} labelPosition="right" className="fredux-radio-inline" validate={[rule.required]} >
                                    <FormControlLabel value="male" className="col-md-2" control={<Radio color="primary" />} label="Female" />
                                    <FormControlLabel value="female" className="col-md-2" control={<Radio color="primary" />} label="Female" />
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Conent</label>
                        <div className="col-md-10">
                            <Field name="memo" component={renderEditor} fullWidth={true} initValues="sdfsdf" multiLine={true} validate={[rule.required]} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Conent 2</label>
                        <div className="col-md-10">
                            <Field name="memo2" component={renderEditor} fullWidth={true} multiLine={true} validate={[rule.required]} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Meta Keyword</label>
                        <div className="col-md-10">
                            <Field name="meta_keyword" component={renderTextField} fullWidth={true} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Meta Tag</label>
                        <div className="col-md-10">
                            <Field name="meta_tag" component={renderTextField} fullWidth={true} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-2">Conent 2</label>
                        <div className="col-md-10">
                            <Field name="memo2" component={renderEditor} fullWidth={true} multiLine={true} validate={[rule.required]} />
                        </div>
                    </div>
                    <div className="form-actions">
                        <div className="row">
                            <div className="col-md-offset-5 col-md-7">
                                <button className="btn green" type="submit"><i className="fa fa-save" /> Save</button>
                                <Link
                                    to="/news/list"
                                    className="btn default"
                                ><i className="fa fa-arrow-left" /> Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

FormContent = reduxForm({enableReinitialize: true})(FormContent)

const mapStateToProps = state => {
    console.log(state);
    return {
        initialValues: state.reducerReduxForm
    }
}

FormContent = connect(
    mapStateToProps
)(FormContent);

export default FormContent;
