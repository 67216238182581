import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/Button';

import { renderDatePicker, renderTextField, renderRadioGroup } from 'my-utils/components/redux-form/render';
import * as rule from 'my-utils/components/redux-form/validate-rules';
import { RoutesService } from 'my-routes';

const Form = props => {
	const { handleSubmit, submitting, reset } = props;

	return (
		<form className="form-horizontal " onSubmit={handleSubmit}>
			<div className="form-body">
				<div className="form-group">
					<label className="control-label col-md-2">Name <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="name" component={renderTextField} fullWidth={true} validate={[rule.required]} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Content <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="content" multiline={true} rows="4" component={renderTextField} fullWidth={true} validate={[rule.required]}/>
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Salary <span className="required">*</span></label>
					<div className="col-md-10">
						<Field name="salary" component={renderTextField} fullWidth={true} validate={[rule.required]} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Working Place</label>
					<div className="col-md-10">
						<Field name="working_place" component={renderTextField} fullWidth={true} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Description</label>
					<div className="col-md-10">
						<Field name="description" multiline={true} rows="4" component={renderTextField} fullWidth={true} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Requirement</label>
					<div className="col-md-10">
						<Field name="requirement" multiline={true} rows="4" component={renderTextField} fullWidth={true} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">End Date</label>
					<div className="col-md-10">
						<Field name="end_date" component={renderDatePicker} fullWidth={true} formatDate={'YYYY-MM-DD'} minDate={new Date()} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Ordering</label>
					<div className="col-md-10">
						<Field name="ordering" component={renderTextField} fullWidth={true} validate={[rule.number]} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Status</label>
					<div className="col-md-10">
						<Field name="status" component={renderRadioGroup} labelPosition="right" className="fredux-radio-inline" validate={[rule.required]} >
							<FormControlLabel value="active" className="col-md-2" control={<Radio color="primary" />} label="Active" />
							<FormControlLabel value="inactive" className="col-md-2" control={<Radio color="primary" />} label="InActive" />
						</Field>
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Meta Keyword</label>
					<div className="col-md-10">
						<Field name="metakey" component={renderTextField} fullWidth={true} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Meta Desc</label>
					<div className="col-md-10">
						<Field name="metadesc" component={renderTextField} fullWidth={true} />
					</div>
				</div>
				<div className="form-group">
					<label className="control-label col-md-2">Meta Tag</label>
					<div className="col-md-10">
						<Field name="metatag" component={renderTextField} fullWidth={true} />
					</div>
				</div>
			</div>
			<div className="form-actions">
				<div className="row">
					<div className="col-md-offset-5 col-md-7">
						<button className="btn green" type="submit" disabled={submitting}><i className="fa fa-save" /> Save</button>
						<button className="btn blue" type="button" disabled={submitting} onClick={reset}><i className="fa fa-refresh" /> Clear</button>
						<Link to={ RoutesService.getPath('ADMIN', 'RECRUITMENT_LIST') } className="btn default">
							<i className="fa fa-arrow-left" /> Back
						</Link>
					</div>
				</div>
			</div>
		</form>
	);
}

const FormContent = reduxForm({enableReinitialize: true})(Form);

export default FormContent;